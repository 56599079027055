import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/replay";
import App from './App.tsx';
import './index.css';
import { ThemeProvider } from './lib/ThemeContext';

// Initialize Sentry
Sentry.init({
  dsn: "https://d1f29c627a3c72dc459a786b0e9f2338@o4509026459451392.ingest.us.sentry.io/4509026485272576",
  integrations: [
    Sentry.browserTracingIntegration(),
    new Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when an error occurs
});

const rootElement = document.getElementById('root');

if (rootElement) {
  createRoot(rootElement).render(
    <StrictMode>
      <HelmetProvider>
        <ThemeProvider>
          <BrowserRouter>
            <Sentry.ErrorBoundary 
              fallback={
                <div className="error-boundary p-4 bg-red-50 text-red-900 rounded-md">
                  <h2 className="text-xl font-bold mb-2">Something went wrong</h2>
                  <p className="mb-4">We've been notified about this issue and are working to fix it.</p>
                  <button 
                    onClick={() => window.location.reload()}
                    className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                    type="button"
                  >
                    Reload page
                  </button>
                </div>
              }
            >
              <App />
            </Sentry.ErrorBoundary>
          </BrowserRouter>
        </ThemeProvider>
      </HelmetProvider>
    </StrictMode>
  );
} else {
  console.error('Root element not found');
}
