import React from 'react';
import { Link } from 'react-router-dom';

export default function PrivacyPolicyPage() {
  return (
    <div className="max-w-4xl mx-auto py-12 px-4">
      <h1 className="text-3xl font-bold mb-8 gradient-text">Privacy Policy</h1>
      
      <div className="prose prose-lg dark:prose-invert max-w-none">
        <p className="text-muted-foreground mb-8">
          Last updated: {new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
        </p>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
          <p>
            Welcome to TheViewShed. We respect your privacy and are committed to protecting your personal data.
            This privacy policy will inform you about how we look after your personal data when you visit our website
            and tell you about your privacy rights and how the law protects you.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">The Data We Collect</h2>
          <p>
            We collect and process the following data when you use our website:
          </p>
          <ul className="list-disc pl-6 mt-2 space-y-2">
            <li>
              <strong>Usage Data:</strong> Information about how you use our website, including pages visited, time spent on pages, and other analytical data.
            </li>
            <li>
              <strong>Bookmarks:</strong> If you bookmark views, we store these preferences locally on your device.
            </li>
            <li>
              <strong>Submission Data:</strong> When you submit a view, we collect the information you provide, including location details, descriptions, and optional contact information.
            </li>
            <li>
              <strong>Contact Information:</strong> If you contact us through our contact form, we collect your name, email address, and message content.
            </li>
          </ul>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">How We Use Your Data</h2>
          <p>
            We use your data for the following purposes:
          </p>
          <ul className="list-disc pl-6 mt-2 space-y-2">
            <li>To provide and maintain our service</li>
            <li>To notify you about changes to our service</li>
            <li>To allow you to participate in interactive features of our service when you choose to do so</li>
            <li>To provide customer support</li>
            <li>To gather analysis or valuable information so that we can improve our service</li>
            <li>To monitor the usage of our service</li>
            <li>To detect, prevent and address technical issues</li>
          </ul>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Cookies and Local Storage</h2>
          <p>
            We use cookies and local storage to store certain preferences and improve your experience. This includes:
          </p>
          <ul className="list-disc pl-6 mt-2 space-y-2">
            <li>Remembering your view preferences (grid/list view, compact mode)</li>
            <li>Storing your bookmarked views</li>
            <li>Saving pagination settings</li>
            <li>Caching view data to improve performance</li>
          </ul>
          <p className="mt-4">
            You can choose to disable cookies through your browser settings, but this may affect the functionality of the website.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Data Security</h2>
          <p>
            We implement appropriate security measures to protect your personal data against unauthorized access,
            alteration, disclosure, or destruction. However, no method of transmission over the Internet or method
            of electronic storage is 100% secure, and we cannot guarantee absolute security.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Third-Party Services</h2>
          <p>
            We may employ third-party companies and individuals to facilitate our service, provide the service on our behalf,
            perform service-related services, or assist us in analyzing how our service is used. These third parties have
            access to your personal data only to perform these tasks on our behalf and are obligated not to disclose or use
            it for any other purpose.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Your Data Protection Rights</h2>
          <p>
            Depending on your location, you may have certain rights regarding your personal data, including:
          </p>
          <ul className="list-disc pl-6 mt-2 space-y-2">
            <li>The right to access, update, or delete your information</li>
            <li>The right to rectification</li>
            <li>The right to object to processing</li>
            <li>The right to data portability</li>
            <li>The right to withdraw consent</li>
          </ul>
          <p className="mt-4">
            To exercise any of these rights, please contact us using the information provided below.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
            Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy.
          </p>
          <p className="mt-2">
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
            are effective when they are posted on this page.
          </p>
        </section>
        
        <section>
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us:
          </p>
          <ul className="list-disc pl-6 mt-2 space-y-2">
            <li>By visiting the <Link to="/contact" className="text-primary hover:underline">Contact page</Link> on our website</li>
          </ul>
        </section>
      </div>
    </div>
  );
} 