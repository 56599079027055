import { useEffect, useCallback } from 'react';

interface UseKeyboardNavigationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  enabled?: boolean;
}

export function useKeyboardNavigation({
  currentPage,
  totalPages,
  onPageChange,
  enabled = true,
}: UseKeyboardNavigationProps) {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!enabled) return;

      // Only handle keyboard navigation when not in an input, textarea, or select
      if (
        document.activeElement instanceof HTMLInputElement ||
        document.activeElement instanceof HTMLTextAreaElement ||
        document.activeElement instanceof HTMLSelectElement
      ) {
        return;
      }

      switch (event.key) {
        case 'ArrowLeft':
          if (currentPage > 1) {
            onPageChange(currentPage - 1);
          }
          break;
        case 'ArrowRight':
          if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
          }
          break;
        case 'Home':
          if (event.ctrlKey || event.metaKey) {
            onPageChange(1);
          }
          break;
        case 'End':
          if (event.ctrlKey || event.metaKey) {
            onPageChange(totalPages);
          }
          break;
        default:
          return;
      }
    },
    [currentPage, totalPages, onPageChange, enabled]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
} 