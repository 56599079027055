import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { View } from '../types';

export function usePaginationState(views: View[], bookmarkedViews: View[]) {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(12);
  const [bookmarkCurrentPage, setBookmarkCurrentPage] = useState(1);
  const [bookmarkPerPage, setBookmarkPerPage] = useState(12);
  
  const location = useLocation();
  const navigate = useNavigate();
  
  // Initialize from URL if available
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    
    // Main pagination
    const pageParam = params.get('page');
    const perPageParam = params.get('perPage');
    
    if (pageParam) {
      const page = parseInt(pageParam, 10);
      if (!isNaN(page) && page > 0) {
        setCurrentPage(page);
      }
    }
    
    if (perPageParam) {
      const itemsPerPage = parseInt(perPageParam, 10);
      if (!isNaN(itemsPerPage) && itemsPerPage > 0) {
        setPerPage(itemsPerPage);
      }
    }
    
    // Bookmark pagination
    const bookmarkPageParam = params.get('bookmark_page');
    const bookmarkPerPageParam = params.get('bookmark_perPage');
    
    if (bookmarkPageParam) {
      const page = parseInt(bookmarkPageParam, 10);
      if (!isNaN(page) && page > 0) {
        setBookmarkCurrentPage(page);
      }
    }
    
    if (bookmarkPerPageParam) {
      const itemsPerPage = parseInt(bookmarkPerPageParam, 10);
      if (!isNaN(itemsPerPage) && itemsPerPage > 0) {
        setBookmarkPerPage(itemsPerPage);
      }
    }
  }, [location.search]);
  
  // Reset to first page when views change
  useEffect(() => {
    setCurrentPage(1);
  }, [views.length]);
  
  // Reset to first page when bookmarked views change
  useEffect(() => {
    setBookmarkCurrentPage(1);
  }, [bookmarkedViews.length]);
  
  // Calculate pagination for main views
  const totalViews = views.length;
  const totalPages = Math.max(1, Math.ceil(totalViews / perPage));
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = Math.min(startIndex + perPage, totalViews);
  const paginatedViews = views.slice(startIndex, endIndex);
  
  // Calculate pagination for bookmarked views
  const totalBookmarkViews = bookmarkedViews.length;
  const totalBookmarkPages = Math.max(1, Math.ceil(totalBookmarkViews / bookmarkPerPage));
  const bookmarkStartIndex = (bookmarkCurrentPage - 1) * bookmarkPerPage;
  const bookmarkEndIndex = Math.min(bookmarkStartIndex + bookmarkPerPage, totalBookmarkViews);
  const paginatedBookmarkedViews = bookmarkedViews.slice(bookmarkStartIndex, bookmarkEndIndex);
  
  // Handle page changes
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    
    // Update URL
    const params = new URLSearchParams(location.search);
    
    // Preserve view parameter
    const viewParam = params.get('view');
    
    // Create new params
    const newParams = new URLSearchParams();
    newParams.set('page', page.toString());
    newParams.set('perPage', perPage.toString());
    
    // Add view parameter if it exists
    if (viewParam) {
      newParams.set('view', viewParam);
    }
    
    navigate({ search: newParams.toString() }, { replace: true });
    
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  const handlePerPageChange = (newPerPage: number) => {
    setPerPage(newPerPage);
    setCurrentPage(1);
    
    // Update URL
    const params = new URLSearchParams(location.search);
    
    // Preserve view parameter
    const viewParam = params.get('view');
    
    // Create new params
    const newParams = new URLSearchParams();
    newParams.set('page', '1');
    newParams.set('perPage', newPerPage.toString());
    
    // Add view parameter if it exists
    if (viewParam) {
      newParams.set('view', viewParam);
    }
    
    navigate({ search: newParams.toString() }, { replace: true });
  };
  
  // Handle bookmark page changes
  const handleBookmarkPageChange = (page: number) => {
    setBookmarkCurrentPage(page);
    
    // Update URL
    const params = new URLSearchParams(location.search);
    
    // Preserve view parameter
    const viewParam = params.get('view');
    
    // Create new params
    const newParams = new URLSearchParams();
    newParams.set('bookmark_page', page.toString());
    newParams.set('bookmark_perPage', bookmarkPerPage.toString());
    
    // Add view parameter if it exists
    if (viewParam) {
      newParams.set('view', viewParam);
    }
    
    navigate({ search: newParams.toString() }, { replace: true });
    
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  const handleBookmarkPerPageChange = (newPerPage: number) => {
    setBookmarkPerPage(newPerPage);
    setBookmarkCurrentPage(1);
    
    // Update URL
    const params = new URLSearchParams(location.search);
    
    // Preserve view parameter
    const viewParam = params.get('view');
    
    // Create new params
    const newParams = new URLSearchParams();
    newParams.set('bookmark_page', '1');
    newParams.set('bookmark_perPage', newPerPage.toString());
    
    // Add view parameter if it exists
    if (viewParam) {
      newParams.set('view', viewParam);
    }
    
    navigate({ search: newParams.toString() }, { replace: true });
  };
  
  return {
    // Main pagination
    currentPage,
    perPage,
    totalPages,
    totalViews,
    paginatedViews,
    handlePageChange,
    handlePerPageChange,
    
    // Bookmark pagination
    bookmarkCurrentPage,
    bookmarkPerPage,
    totalBookmarkPages,
    totalBookmarkViews,
    paginatedBookmarkedViews,
    handleBookmarkPageChange,
    handleBookmarkPerPageChange,
  };
} 