import React from 'react';
import { SEO } from '../../lib/seo';
import type { View } from '../../types';

interface ViewPageSEOProps {
  view: View;
}

// Extended view properties for SEO purposes - these would come from the API
interface ExtendedView extends View {
  title?: string;
  description?: string;
  images?: string[];
  sourceLatitude?: number;
  sourceLongitude?: number;
  destinationLatitude?: number;
  destinationLongitude?: number;
  sourceLocationName?: string;
  destinationLocationName?: string;
}

export function ViewPageSEO({ view }: ViewPageSEOProps) {
  if (!view) return null;

  // Cast to extended view
  const viewData = view as ExtendedView;
  
  // Use existing View properties if extended ones aren't available
  const title = viewData.title || `${viewData.source} to ${viewData.destination} View`;
  const description = viewData.description || 
    `Explore this remarkable line-of-sight view from ${viewData.source}, ${viewData.state}, ${viewData.country} to ${viewData.destination}, ${viewData.destinationState}, ${viewData.destinationCountry || viewData.destinationContinent}.`;
  const images = viewData.images || (viewData.image ? [viewData.image] : []);
  const sourceLocationName = viewData.sourceLocationName || 
    `${viewData.source}, ${viewData.state}, ${viewData.country}`;
  const destinationLocationName = viewData.destinationLocationName || 
    `${viewData.destination}, ${viewData.destinationState}, ${viewData.destinationCountry || viewData.destinationContinent}`;
  
  const imageUrl = images.length > 0 ? images[0] : '';
  
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "TouristAttraction",
    "name": title,
    "description": description,
    "url": `https://theviewshed.com/view/${viewData.id}`,
    ...(imageUrl && { "image": imageUrl }),
    ...(viewData.sourceLatitude && viewData.sourceLongitude && {
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": viewData.sourceLatitude,
        "longitude": viewData.sourceLongitude
      }
    }),
    "isAccessibleForFree": true,
    "touristType": ["Nature enthusiasts", "Photographers", "Hikers", "Sightseers"],
    "additionalProperty": [
      {
        "@type": "PropertyValue",
        "name": "Source Location",
        "value": sourceLocationName
      },
      {
        "@type": "PropertyValue",
        "name": "Destination Location",
        "value": destinationLocationName
      },
      ...(viewData.destinationLatitude && viewData.destinationLongitude ? [{
        "@type": "PropertyValue",
        "name": "Destination Coordinates",
        "value": `${viewData.destinationLatitude}, ${viewData.destinationLongitude}`
      }] : [])
    ]
  };

  return (
    <SEO
      title={`${title} | TheViewShed`}
      description={description}
      keywords={`${sourceLocationName}, ${destinationLocationName}, line of sight view, viewshed, vista, viewpoint, scenic view`}
      structuredData={structuredData}
      image={imageUrl || '/default-view-image.jpg'}
    />
  );
} 