import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import { Copy, Facebook, Twitter, Linkedin, Mail } from 'lucide-react';
import { useToast } from './ui/toast';

interface ShareDialogProps {
  open: boolean;
  onClose: () => void;
  url: string;
}

export function ShareDialog({ open, onClose, url }: ShareDialogProps) {
  const { toast } = useToast();
  
  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    toast({
      title: "Link copied!",
      description: "The link has been copied to your clipboard."
    });
  };
  
  const shareOptions = [
    {
      name: 'Facebook',
      icon: <Facebook className="h-5 w-5" />,
      color: 'bg-[#1877F2] hover:bg-[#1877F2]/90',
      onClick: () => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank')
    },
    {
      name: 'Twitter',
      icon: <Twitter className="h-5 w-5" />,
      color: 'bg-[#1DA1F2] hover:bg-[#1DA1F2]/90',
      onClick: () => window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=Check out this amazing view on TheViewShed!`, '_blank')
    },
    {
      name: 'LinkedIn',
      icon: <Linkedin className="h-5 w-5" />,
      color: 'bg-[#0A66C2] hover:bg-[#0A66C2]/90',
      onClick: () => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`, '_blank')
    },
    {
      name: 'Email',
      icon: <Mail className="h-5 w-5" />,
      color: 'bg-gray-600 hover:bg-gray-700',
      onClick: () => window.open(`mailto:?subject=Check out this view on TheViewShed&body=${encodeURIComponent(url)}`, '_blank')
    }
  ];

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md bg-slate-900 text-slate-100 border-slate-800">
        <DialogHeader>
          <DialogTitle className="text-slate-100">Share this view</DialogTitle>
        </DialogHeader>
        
        <div className="flex flex-col space-y-4 py-4">
          <div className="flex items-center space-x-2">
            <div className="flex-1 flex items-center border border-slate-700 rounded-md overflow-hidden">
              <input 
                type="text" 
                value={url} 
                readOnly 
                className="flex-1 px-3 py-2 bg-slate-800 text-slate-100 text-sm focus:outline-none"
              />
              <button
                type="button"
                onClick={copyToClipboard}
                className="p-2 bg-slate-700 hover:bg-slate-600 transition-colors text-slate-300"
                aria-label="Copy to clipboard"
              >
                <Copy className="h-4 w-4" />
              </button>
            </div>
          </div>
          
          <div className="grid grid-cols-2 gap-2 sm:grid-cols-4">
            {shareOptions.map((option) => (
              <button
                type="button"
                key={option.name}
                onClick={option.onClick}
                className={`flex items-center justify-center space-x-2 rounded-md px-4 py-2 text-white ${option.color} transition-colors`}
              >
                {option.icon}
                <span className="text-sm">{option.name}</span>
              </button>
            ))}
          </div>
          
          <div className="pt-4 text-center text-sm text-slate-400">
            <p>Share this view with friends and fellow enthusiasts!</p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
} 