import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Routes, Route, Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Mountain, Grid, List, Mountain as Mountain2, Ruler, Compass, BarChart3, Map as MapIcon, Search, ChevronLeft, ChevronRight, Bookmark, Share2, Info, ArrowUpCircle, Download, Maximize2, Minimize2, Menu, X, Sun, Moon, RefreshCcw } from 'lucide-react';
import ViewCard from './components/ViewCard';
import { ViewModal } from './components/ViewModal';
import type { View } from './types';
import { ToastProvider, ToastViewport } from './components/ui/toast';
import { ThemeToggle } from './components/ThemeToggle';
import { useTheme } from './lib/ThemeContext';
import { fetchViews } from './lib/api';
import { FilterBar, FilterState } from './components/FilterBar';
import { cn } from './lib/utils';
import { motion, AnimatePresence } from 'framer-motion';
import { useMediaQuery } from './hooks/useMediaQuery';
import { ViewSkeleton } from './components/ViewSkeleton';
import { ShareDialog } from './components/ShareDialog';
import { Pagination } from './components/Pagination';
import { usePaginationState } from './hooks/usePaginationState';
import { SectionHeader } from './components/SectionHeader';
import { Analytics } from '@vercel/analytics/react';
import { NavigationBar } from './components/NavigationBar';
import { Footer } from './components/Footer';
import { useToast } from './components/ui/toast';

// Import page components
import HomePage from './pages/home/page';
import FAQPage from './pages/faq/page';
import SubmitViewPage from './pages/submit/page';
import ContactPage from './pages/contact/page';
import PrivacyPolicyPage from './pages/privacy/page';
import TermsAndConditionsPage from './pages/terms/page';
import ViewPage from './pages/view/page';

// Define types for the layout components
interface ViewsLayoutProps {
  views: View[];
  paginatedGroupedViews: {
    range: string;
    views: View[];
  }[];
  isLoading: boolean;
  error: string | null;
  viewMode: 'grid' | 'list';
  setViewMode: (mode: 'grid' | 'list') => void;
  isCompactMode: boolean;
  setIsCompactMode: (isCompact: boolean) => void;
  handleFilter: (filters: FilterState) => void;
  setSelectedView: (view: View | null) => void;
  toggleBookmark: (viewId: string) => void;
  isBookmarked: (viewId: string) => boolean;
  handleShare: (view: View) => void;
  viewTransition: 'fade' | 'slide';
  setViewTransition: (transition: 'fade' | 'slide') => void;
  currentPage: number;
  totalPages: number;
  perPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
  onPerPageChange: (perPage: number) => void;
}

interface BookmarksLayoutProps {
  bookmarkedViews: View[];
  paginatedGroupedViews: {
    range: string;
    views: View[];
  }[];
  isLoading: boolean;
  viewMode: 'grid' | 'list';
  setViewMode: (mode: 'grid' | 'list') => void;
  isCompactMode: boolean;
  setIsCompactMode: (isCompact: boolean) => void;
  handleFilter: (filters: FilterState) => void;
  setSelectedView: (view: View | null) => void;
  toggleBookmark: (viewId: string) => void;
  isBookmarked: (viewId: string) => boolean;
  handleShare: (view: View) => void;
  exportData: () => void;
  viewTransition: 'fade' | 'slide';
  setViewTransition: (transition: 'fade' | 'slide') => void;
  currentPage: number;
  totalPages: number;
  perPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
  onPerPageChange: (perPage: number) => void;
}

function App() {
  const { theme, toggleTheme } = useTheme();
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [selectedView, setSelectedView] = useState<View | null>(null);
  const [views, setViews] = useState<View[]>([]);
  const [filteredViews, setFilteredViews] = useState<View[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [bookmarkedViews, setBookmarkedViews] = useState<string[]>(() => {
    const saved = localStorage.getItem('bookmarkedViews');
    return saved ? JSON.parse(saved) : [];
  });
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [viewTransition, setViewTransition] = useState<'fade' | 'slide'>('fade');
  const [isCompactMode, setIsCompactMode] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  
  // 1. Move filters state to App component
  const [filters, setFilters] = useState<FilterState>({
    search: null,
    status: ['confirmed'], // Default to 'confirmed' status
    sourceStates: [],
    sourceCountries: [],
    sourceContinent: [],
    destinationStates: [],
    destinationCountries: [],
    destinationContinent: [],
    distance: "50",
    sortBy: 'distance',
    sortDirection: 'asc',
    // Add any missing properties from FilterState
    category: [],
    seasons: [],
    location: ''
  });
  
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');
  
  // Define isBookmarked function before using it
  const isBookmarked = (viewId: string) => bookmarkedViews.includes(viewId);
  
  // Get bookmarked views
  const filteredBookmarkedViews = filteredViews.filter(view => isBookmarked(view.id));
  
  // Use the pagination state hook
  const {
    currentPage,
    perPage,
    totalPages,
    totalViews,
    paginatedViews,
    handlePageChange,
    handlePerPageChange,
    bookmarkCurrentPage,
    bookmarkPerPage,
    totalBookmarkPages,
    totalBookmarkViews,
    paginatedBookmarkedViews,
    handleBookmarkPageChange,
    handleBookmarkPerPageChange,
  } = usePaginationState(filteredViews, filteredBookmarkedViews);
  
  useEffect(() => {
    const loadViews = async () => {
      try {
        setIsLoading(true);
        const data = await fetchViews();
        setViews(data);
        setFilteredViews(data); // Initialize filtered views with all views
        setError(null);
      } catch (err) {
        setError('Failed to load views. Please try again later.');
        console.error('Error loading views:', err);
      } finally {
        setIsLoading(false);
      }
    };
    
    loadViews();
  }, []);

  // Handle URL parameters for direct linking
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const viewId = params.get('view');
    
    if (viewId) {
      const view = views.find(v => v.id === viewId);
      if (view) {
        setSelectedView(view);
      }
    }
  }, [location.search, views]);
  
  // Save bookmarks to localStorage
  useEffect(() => {
    localStorage.setItem('bookmarkedViews', JSON.stringify(bookmarkedViews));
  }, [bookmarkedViews]);
  
  // Show/hide scroll to top button
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollToTop(window.scrollY > 500);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleFilter = (filters: FilterState) => {
    const filtered = views.filter(view => {
      // Search filter
      const matchesSearch = !filters.search || 
        view.source.toLowerCase().includes(filters.search.toLowerCase()) ||
        view.destination.toLowerCase().includes(filters.search.toLowerCase()) ||
        view.state.toLowerCase().includes(filters.search.toLowerCase()) ||
        view.country.toLowerCase().includes(filters.search.toLowerCase()) ||
        view.destinationState.toLowerCase().includes(filters.search.toLowerCase());
      
      // Normalize status values for consistent comparison
      const normalizeStatus = (status: string): string => {
        // Handle if-clear format conversion
        if (status === 'if-clear') return 'ifclear';
        return status;
      };
      
      // Status filter - ensure we always have at least one status filter active
      const statuses = filters.status && filters.status.length > 0 ? filters.status : ['confirmed']; 
      const matchesStatus = 
        statuses.some(filterStatus => 
          normalizeStatus(filterStatus) === normalizeStatus(view.status)
        );
      
      // Source state filter
      const matchesSourceState = filters.sourceStates.length === 0 || 
        filters.sourceStates.includes(view.state);
      
      // Source country filter
      const matchesSourceCountry = filters.sourceCountries.length === 0 || 
        filters.sourceCountries.includes(view.country);
      
      // Source continent filter
      const matchesSourceContinent = filters.sourceContinent.length === 0 || 
        (view.continent && filters.sourceContinent.includes(view.continent));
      
      // Destination state filter
      const matchesDestState = filters.destinationStates.length === 0 || 
        filters.destinationStates.includes(view.destinationState);
      
      // Destination country filter - assuming destination country is available
      const matchesDestCountry = filters.destinationCountries.length === 0 || 
        filters.destinationCountries.includes(view.country);
      
      // Destination continent filter
      const matchesDestContinent = filters.destinationContinent.length === 0 || 
        (view.destinationContinent && filters.destinationContinent.includes(view.destinationContinent));
      
      // Distance filter
      let matchesDistance = true;
      if (filters.distance) {
        const distance = parseFloat(view.distance.split(' ')[0]);
        const maxDistance = parseFloat(filters.distance);
        matchesDistance = distance <= maxDistance;
      }
      
      return matchesSearch && 
             matchesStatus && 
             matchesSourceState && 
             matchesSourceCountry && 
             matchesSourceContinent &&
             matchesDestState && 
             matchesDestCountry && 
             matchesDestContinent &&
             matchesDistance;
    });
    
    // Apply sorting
    const sorted = [...filtered].sort((a, b) => {
      if (filters.sortBy === 'distance') {
        const distanceA = parseFloat(a.distance.split(' ')[0]);
        const distanceB = parseFloat(b.distance.split(' ')[0]);
        return filters.sortDirection === 'asc' ? distanceA - distanceB : distanceB - distanceA;
      }
      // Add more sorting options as needed
      return 0;
    });
    
    setFilteredViews(sorted);
  };

  // Apply filters on initial load with confirmed status
  useEffect(() => {
    // When the app initially loads, make sure we're filtering by confirmed status
    handleFilter(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to only run once on mount

  const distanceRanges = ['500+km', '100-500km', '10-100km'];

  // Group the paginated views by distance range
  const paginatedGroupedViews = distanceRanges.map(range => {
    let min = 0;
    let max = Number.POSITIVE_INFINITY;
    
    if (range === '500+km') {
      min = 500;
    } else if (range === '100-500km') {
      min = 100;
      max = 500;
    } else if (range === '10-100km') {
      min = 10;
      max = 100;
    }
    
    return {
      range,
      views: paginatedViews.filter(view => {
        const distance = Number.parseInt(view.distance.split(' ')[0], 10);
        return distance >= min && distance < max;
      })
    };
  });

  // Create a separate grouped views for bookmarks
  const bookmarkPaginatedGroupedViews = distanceRanges.map(range => {
    let min = 0;
    let max = Number.POSITIVE_INFINITY;
    
    if (range === '500+km') {
      min = 500;
    } else if (range === '100-500km') {
      min = 100;
      max = 500;
    } else if (range === '10-100km') {
      min = 10;
      max = 100;
    }
    
    return {
      range,
      views: paginatedBookmarkedViews.filter(view => {
        const distance = Number.parseInt(view.distance.split(' ')[0], 10);
        return distance >= min && distance < max;
      })
    };
  });

  const toggleBookmark = (viewId: string) => {
    setBookmarkedViews(prev => 
      prev.includes(viewId)
        ? prev.filter(id => id !== viewId)
        : [...prev, viewId]
    );
  };
  
  const handleShare = (view: View) => {
    const url = `${window.location.origin}/?view=${view.id}`;
    setShareUrl(url);
    setIsShareDialogOpen(true);
  };
  
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  const exportData = () => {
    const bookmarkedViewsData = views.filter(view => isBookmarked(view.id));
    const dataStr = JSON.stringify(bookmarkedViewsData, null, 2);
    const dataUri = `data:application/json;charset=utf-8,${encodeURIComponent(dataStr)}`;
    
    const exportFileDefaultName = `viewshed-bookmarks-${new Date().toISOString().slice(0, 10)}.json`;
    
    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  };

  return (
    <ToastProvider>
      <div className={`min-h-screen space-background ${theme === 'dark' ? 'dark' : ''}`}>
        <div className="min-h-screen bg-background/80 backdrop-blur-sm">
          {/* Header */}
          <header className="bg-background/90 backdrop-blur-md shadow-sm sticky top-0 z-[7777] border-b border-border">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between items-center h-16">
                <div className="flex items-center">
                    <Link to="/" className="flex items-center">
                      <img src="/favicon.svg" alt="TheViewShed Logo" className="h-10 w-10" />
                      <h1 className="ml-2 text-xl font-bold gradient-text">TheViewShed</h1>
                    </Link>
                  </div>
                  <nav className="hidden md:flex space-x-8">
                    <Link to="/" className={`transition-colors ${location.pathname === '/' ? 'text-primary font-medium' : 'text-foreground hover:text-primary'}`}>Home</Link>
                    {/* <Link to="/bookmarks" className={`transition-colors ${location.pathname === '/bookmarks' ? 'text-primary font-medium' : 'text-foreground hover:text-primary'}`}>Bookmarks</Link> */}
                    <Link to="/submit" className={`transition-colors ${location.pathname === '/submit' ? 'text-primary font-medium' : 'text-foreground hover:text-primary'}`}>Submit View</Link>
                    <Link to="/faq" className={`transition-colors ${location.pathname === '/faq' ? 'text-primary font-medium' : 'text-foreground hover:text-primary'}`}>FAQ</Link>
                    <Link to="/contact" className={`transition-colors ${location.pathname === '/contact' ? 'text-primary font-medium' : 'text-foreground hover:text-primary'}`}>Contact</Link>
                  </nav>
                  <div className="flex items-center space-x-2">
                    <button 
                      onClick={toggleTheme} 
                      className="relative p-2 rounded-md bg-muted hover:bg-muted/80 transition-colors group overflow-hidden"
                      aria-label="Toggle theme"
                    >
                      {theme === 'dark' ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
                      
                      {/* Sci-fi highlight */}
                      <span className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        <span className="absolute top-0 left-0 w-2 h-0.5 bg-primary" />
                        <span className="absolute top-0 left-0 w-0.5 h-2 bg-primary" />
                        <span className="absolute top-0 right-0 w-2 h-0.5 bg-primary" />
                        <span className="absolute top-0 right-0 w-0.5 h-2 bg-primary" />
                        <span className="absolute bottom-0 left-0 w-2 h-0.5 bg-primary" />
                        <span className="absolute bottom-0 left-0 w-0.5 h-2 bg-primary" />
                        <span className="absolute bottom-0 right-0 w-2 h-0.5 bg-primary" />
                        <span className="absolute bottom-0 right-0 w-0.5 h-2 bg-primary" />
                      </span>
                    </button>
                    <button
                      type="button"
                      className="md:hidden p-2 rounded-md text-foreground hover:bg-muted/50"
                      onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    >
                      {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
                    </button>
                  </div>
                </div>
              </div>
              
              {/* Mobile Menu */}
              <AnimatePresence>
                {isMobileMenuOpen && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    className="md:hidden bg-background/95 backdrop-blur-md border-b border-border"
                  >
                    <nav className="flex flex-col space-y-2 p-4">
                      <Link 
                        to="/" 
                        className={`px-4 py-2 rounded-md ${location.pathname === '/' ? 'bg-primary/10 text-primary' : 'hover:bg-muted/50'}`}
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        Home
                      </Link>
                      <Link 
                        to="/bookmarks" 
                        className={`px-4 py-2 rounded-md ${location.pathname === '/bookmarks' ? 'bg-primary/10 text-primary' : 'hover:bg-muted/50'}`}
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        Bookmarks
                      </Link>
                      <Link 
                        to="/submit" 
                        className={`px-4 py-2 rounded-md ${location.pathname === '/submit' ? 'bg-primary/10 text-primary' : 'hover:bg-muted/50'}`}
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        Submit View
                      </Link>
                      <Link 
                        to="/faq" 
                        className={`px-4 py-2 rounded-md ${location.pathname === '/faq' ? 'bg-primary/10 text-primary' : 'hover:bg-muted/50'}`}
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        FAQ
                      </Link>
                      <Link 
                        to="/contact" 
                        className={`px-4 py-2 rounded-md ${location.pathname === '/contact' ? 'bg-primary/10 text-primary' : 'hover:bg-muted/50'}`}
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        Contact
                      </Link>
                    </nav>
                  </motion.div>
                )}
              </AnimatePresence>
          </header>

          {/* Main Content */}
          <Routes>
            <Route path="/" element={
              <ViewsLayout 
                views={filteredViews}
                paginatedGroupedViews={paginatedGroupedViews}
                isLoading={isLoading}
                error={error}
                viewMode={viewMode}
                setViewMode={setViewMode}
                isCompactMode={isCompactMode}
                setIsCompactMode={setIsCompactMode}
                handleFilter={handleFilter}
                setSelectedView={setSelectedView}
                toggleBookmark={toggleBookmark}
                isBookmarked={isBookmarked}
                handleShare={handleShare}
                viewTransition={viewTransition}
                setViewTransition={setViewTransition}
                currentPage={currentPage}
                totalPages={totalPages}
                perPage={perPage}
                totalItems={totalViews}
                onPageChange={handlePageChange}
                onPerPageChange={handlePerPageChange}
                filters={filters}
                setFilters={setFilters}
              />
            } />
            <Route path="/bookmarks" element={
              <BookmarksLayout 
                bookmarkedViews={filteredBookmarkedViews}
                paginatedGroupedViews={bookmarkPaginatedGroupedViews}
                isLoading={isLoading}
                viewMode={viewMode}
                setViewMode={setViewMode}
                isCompactMode={isCompactMode}
                setIsCompactMode={setIsCompactMode}
                handleFilter={handleFilter}
                setSelectedView={setSelectedView}
                toggleBookmark={toggleBookmark}
                isBookmarked={isBookmarked}
                handleShare={handleShare}
                exportData={exportData}
                viewTransition={viewTransition}
                setViewTransition={setViewTransition}
                currentPage={bookmarkCurrentPage}
                totalPages={totalBookmarkPages}
                perPage={bookmarkPerPage}
                totalItems={totalBookmarkViews}
                onPageChange={handleBookmarkPageChange}
                onPerPageChange={handleBookmarkPerPageChange}
                filters={filters}
                setFilters={setFilters}
              />
            } />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/submit" element={<SubmitViewPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            <Route path="/terms" element={<TermsAndConditionsPage />} />
            <Route path="/view/:id" element={<ViewPage />} />
          </Routes>

          {/* View Modal */}
          <AnimatePresence>
            {selectedView && (
              <ViewModal
                view={selectedView}
                open={selectedView !== null}
                onClose={() => {
                  setSelectedView(null);
                  // Remove the view parameter from URL when closing modal
                  const params = new URLSearchParams(location.search);
                  params.delete('view');
                  navigate({ search: params.toString() }, { replace: true });
                }}
                onShare={() => handleShare(selectedView)}
                onNext={() => {
                  // Find current view index in the filtered array of views
                  const currentIndex = filteredViews.findIndex(v => v.id === selectedView.id);
                  // If there's a next view, select it
                  if (currentIndex < filteredViews.length - 1) {
                    setSelectedView(filteredViews[currentIndex + 1]);
                  }
                }}
                onPrevious={() => {
                  // Find current view index in the filtered array of views
                  const currentIndex = filteredViews.findIndex(v => v.id === selectedView.id);
                  // If there's a previous view, select it
                  if (currentIndex > 0) {
                    setSelectedView(filteredViews[currentIndex - 1]);
                  }
                }}
              />
            )}
          </AnimatePresence>
          
          {/* Share Dialog */}
          <ShareDialog 
            open={isShareDialogOpen} 
            onClose={() => setIsShareDialogOpen(false)}
            url={shareUrl}
          />
          
          {/* Scroll to Top Button */}
          <AnimatePresence>
            {showScrollToTop && (
              <motion.button
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                onClick={scrollToTop}
                className="fixed bottom-6 right-6 p-3 rounded-full bg-primary text-primary-foreground shadow-lg hover:bg-primary/90 transition-colors z-20"
                aria-label="Scroll to top"
              >
                <ArrowUpCircle className="h-6 w-6" />
              </motion.button>
            )}
          </AnimatePresence>
          
          <ToastViewport />
        </div>

        {/* Footer - Make sure this is outside the Routes component */}
        <footer className="bg-card border-t border-border py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="mb-4 md:mb-0">
                <p className="text-sm text-muted-foreground">
                  © {new Date().getFullYear()} TheViewShed. All rights reserved.
                </p>
              </div>
              <div className="flex space-x-6">
                <Link to="/privacy" className="text-sm text-muted-foreground hover:text-primary transition-colors">
                  Privacy Policy
                </Link>
                <Link to="/terms" className="text-sm text-muted-foreground hover:text-primary transition-colors">
                  Terms & Conditions
                </Link>
                <Link to="/contact" className="text-sm text-muted-foreground hover:text-primary transition-colors">
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </ToastProvider>
  );
}

// ViewsLayout Component with proper type annotation
function ViewsLayout({ 
  views, 
  paginatedGroupedViews, 
  isLoading, 
  error, 
  viewMode, 
  setViewMode, 
  isCompactMode, 
  setIsCompactMode, 
  handleFilter, 
  setSelectedView, 
  toggleBookmark, 
  isBookmarked, 
  handleShare, 
  viewTransition, 
  setViewTransition,
  currentPage,
  totalPages,
  perPage,
  totalItems,
  onPageChange,
  onPerPageChange,
  filters,
  setFilters
}: ViewsLayoutProps & {
  filters: FilterState;
  setFilters: React.Dispatch<React.SetStateAction<FilterState>>;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  
  return (
    <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <Analytics />
      {/* FilterBar Component */}
      <div className="mb-8">
        <FilterBar 
          onFilter={handleFilter} 
          views={views} 
          isLoading={isLoading}
          initialFilters={filters}
        />
        
        <div className="mt-4 flex flex-wrap justify-between items-center gap-4">
          <div className="flex items-center space-x-2">
            {/* View mode toggles */}
            <button
              type="button"
              onClick={() => setViewMode('grid')}
              className={`p-2 rounded-md border ${viewMode === 'grid' ? 'bg-primary/20 text-primary border-primary' : 'bg-background border-border'} transition-colors`}
              aria-label="Grid view"
            >
              <Grid className="h-4 w-4" />
            </button>
            <button
              type="button"
              onClick={() => setViewMode('list')}
              className={`p-2 rounded-md border ${viewMode === 'list' ? 'bg-primary/20 text-primary border-primary' : 'bg-background border-border'} transition-colors`}
              aria-label="List view"
            >
              <List className="h-4 w-4" />
            </button>
            
            {/* Active Filters Display */}
            <div className="flex flex-wrap gap-1.5 ml-3">
              {filters.status.length > 0 && filters.status.map(status => (
                <div key={status} className="inline-flex items-center gap-1 bg-primary/10 text-primary px-2.5 py-1 rounded-full text-xs font-medium">
                  <span>Status: {status.charAt(0).toUpperCase() + status.slice(1)}</span>
                  <button 
                    onClick={() => {
                      const newFilters = {...filters, status: filters.status.filter(s => s !== status)};
                      handleFilter(newFilters);
                      setFilters(newFilters);
                    }}
                    className="h-4 w-4 flex items-center justify-center rounded-full hover:bg-primary/20"
                    aria-label={`Remove ${status} filter`}
                  >
                    <X className="h-3 w-3" />
                  </button>
                </div>
              ))}
              
              {filters.sourceStates.length > 0 && filters.sourceStates.map(state => (
                <div key={`source-${state}`} className="inline-flex items-center gap-1 bg-blue-500/10 text-blue-500 px-2.5 py-1 rounded-full text-xs font-medium">
                  <span>Source: {state}</span>
                  <button 
                    onClick={() => {
                      const newFilters = {...filters, sourceStates: filters.sourceStates.filter(s => s !== state)};
                      handleFilter(newFilters);
                      setFilters(newFilters);
                    }}
                    className="h-4 w-4 flex items-center justify-center rounded-full hover:bg-blue-500/20"
                    aria-label={`Remove ${state} filter`}
                  >
                    <X className="h-3 w-3" />
                  </button>
                </div>
              ))}
              
              {/* Add similar blocks for other active filters: sourceCountries, destinationStates, etc. */}
              
              {/* Clear all filters button - only show if any filters are active */}
              {(filters.status.length > 0 || 
                filters.sourceStates.length > 0 || 
                filters.sourceCountries.length > 0 || 
                filters.sourceContinent.length > 0 ||
                filters.destinationStates.length > 0 ||
                filters.destinationCountries.length > 0 ||
                filters.destinationContinent.length > 0 ||
                filters.distance !== null) && (
                <button
                  type="button"
                  onClick={() => {
                    const defaultFilters = {
                      search: null,
                      status: ['confirmed'],
                      sourceStates: [],
                      sourceCountries: [],
                      sourceContinent: [],
                      destinationStates: [],
                      destinationCountries: [],
                      destinationContinent: [],
                      distance: "50",
                      sortBy: 'distance',
                      sortDirection: 'asc'
                    };
                    handleFilter(defaultFilters);
                    setFilters(defaultFilters);
                  }}
                  className="text-xs text-muted-foreground hover:text-primary flex items-center gap-1 ml-2"
                >
                  <RefreshCcw className="h-3 w-3" />
                  <span>Reset</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Top Pagination */}
      {!isLoading && !error && views.length > 0 && (
        <div className="mb-8">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            perPage={perPage}
            totalItems={totalItems}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
          />
        </div>
      )}

      {/* Loading State */}
      {isLoading && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {Array.from({ length: 8 }).map((_, index) => (
            <ViewSkeleton key={index} />
          ))}
        </div>
      )}

      {/* Error State */}
      {error && (
        <div className="bg-red-500/10 border border-red-500 text-red-500 p-4 rounded-md mb-8">
          <p>{error}</p>
          <button
            type="button"
            onClick={() => window.location.reload()}
            className="mt-2 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
          >
            Retry
          </button>
        </div>
      )}

      {/* Empty State */}
      {!isLoading && !error && views.length === 0 && (
        <div className="text-center py-20">
          <Mountain className="h-16 w-16 mx-auto text-muted-foreground mb-4" />
          <h2 className="text-2xl font-bold mb-2">No views found</h2>
          <p className="text-muted-foreground">
            Try adjusting your filters to see more results
          </p>
        </div>
      )}

      {/* View Categories */}
      <AnimatePresence mode="wait">
        <motion.div
          key={viewMode + isCompactMode + viewTransition}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {!isLoading && !error && paginatedGroupedViews.map(({ range, views }) => 
            views.length > 0 && (
              <div key={range} className="mb-12">
                <div className="mb-8 relative flex items-center">
                  {/* Futuristic horizontal line with glow effect */}
                  <div className="absolute left-0 right-0 h-[1px] bg-gradient-to-r from-transparent via-primary/50 to-transparent top-1/2 -z-10" />
                  <div className="absolute left-0 right-0 h-[1px] blur-[2px] bg-gradient-to-r from-transparent via-primary/30 to-transparent top-1/2 -z-10" />
                  
                  {/* Pill shape with gradient background */}
                  <div className={cn(
                    "relative inline-flex items-center px-6 py-2.5 rounded-full shadow-lg border border-primary/20",
                    "bg-gradient-to-r",
                    range === '500+km' ? 'from-indigo-600/90 to-blue-600/90 dark:from-indigo-900/90 dark:to-blue-900/90' : 
                    range === '100-500km' ? 'from-blue-600/90 to-cyan-600/90 dark:from-blue-800/90 dark:to-cyan-800/90' : 
                    'from-cyan-600/90 to-teal-600/90 dark:from-cyan-800/90 dark:to-teal-800/90'
                  )}>
                    {/* Subtle glow effect */}
                    <div className={cn(
                      "absolute inset-0 rounded-full blur-md opacity-50 -z-10",
                      range === '500+km' ? 'bg-indigo-600' : 
                      range === '100-500km' ? 'bg-blue-600' : 
                      'bg-cyan-600'
                    )} />
                    
                    {/* Small decorative dots */}
                    <div className="absolute left-0 top-0 w-full h-full">
                      <div className="absolute w-1 h-1 rounded-full bg-white/40 left-2 top-[50%] -translate-y-1/2" />
                      <div className="absolute w-0.5 h-0.5 rounded-full bg-white/40 left-4 top-[35%]" />
                      <div className="absolute w-0.5 h-0.5 rounded-full bg-white/40 left-3 top-[65%]" />
                    </div>
                    
                    {/* Range text with futuristic styling */}
                    <h2 className="text-xl font-bold text-white tracking-wide">
                      {range}
                    </h2>
                  </div>
                  
                  {/* Decorative elements after the pill */}
                  <div className="ml-4 flex items-center">
                    <div className="w-2 h-2 rounded-full bg-primary/70" />
                    <div className="w-8 h-[1px] bg-gradient-to-r from-primary/70 to-transparent" />
                    <div className="w-1 h-1 rounded-full bg-primary/50" />
                    <div className="w-12 h-[1px] bg-gradient-to-r from-primary/50 to-transparent" />
                  </div>
                  
                  {/* Decorative ending elements */}
                  <div className="flex-1 ml-4">
                    <div className="h-[1px] w-full bg-gradient-to-r from-primary/30 to-transparent" />
                  </div>
                </div>
                
                <div className={`${viewMode === 'list' ? 'space-y-4' : 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6'}`}>
                  {views.map(view => (
                    <ViewCard
                      key={view.id}
                      view={view}
                      onClick={() => setSelectedView(view)}
                      isBookmarked={isBookmarked(view.id)}
                      onBookmarkToggle={(e) => {
                        e.stopPropagation();
                        toggleBookmark(view.id);
                      }}
                      onShare={(e) => {
                        e.stopPropagation();
                        handleShare(view);
                      }}
                      isCompact={isCompactMode}
                      isList={viewMode === 'list'}
                    />
                  ))}
                </div>
              </div>
            )
          )}
        </motion.div>
      </AnimatePresence>

      {/* Bottom Pagination */}
      {!isLoading && !error && views.length > 0 && (
        <div className="mt-8">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            perPage={perPage}
            totalItems={totalItems}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
          />
        </div>
      )}
    </main>
  );
}

// BookmarksLayout Component with proper type annotation
function BookmarksLayout({ 
  bookmarkedViews, 
  paginatedGroupedViews, 
  isLoading, 
  viewMode, 
  setViewMode, 
  isCompactMode, 
  setIsCompactMode, 
  handleFilter, 
  setSelectedView, 
  toggleBookmark, 
  isBookmarked, 
  handleShare, 
  exportData, 
  viewTransition, 
  setViewTransition,
  currentPage,
  totalPages,
  perPage,
  totalItems,
  onPageChange,
  onPerPageChange,
  filters,
  setFilters
}: BookmarksLayoutProps & {
  filters: FilterState;
  setFilters: React.Dispatch<React.SetStateAction<FilterState>>;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  
  return (
    <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col md:flex-row md:items-center justify-between mb-8 gap-4">
        <div>
          <h1 className="text-3xl font-bold gradient-text">Your Bookmarks</h1>
          <p className="text-muted-foreground mt-2">
            {bookmarkedViews.length} saved views
          </p>
        </div>
        
        <div className="flex flex-wrap gap-2">
          <button
            type="button"
            onClick={exportData}
            className="flex items-center gap-2 px-4 py-2 bg-primary text-primary-foreground rounded-md hover:bg-primary/90 transition-colors"
          >
            <Download className="h-4 w-4" />
            <span>Export Bookmarks</span>
          </button>
          
          <div className="flex space-x-2">
            <button
              type="button"
              onClick={() => setViewMode('grid')}
              className={`p-2 rounded-md border ${viewMode === 'grid' ? 'bg-primary/20 text-primary border-primary' : 'bg-background border-border'} transition-colors`}
              aria-label="Grid view"
            >
              <Grid className="h-4 w-4" />
            </button>
            <button
              type="button"
              onClick={() => setViewMode('list')}
              className={`p-2 rounded-md border ${viewMode === 'list' ? 'bg-primary/20 text-primary border-primary' : 'bg-background border-border'} transition-colors`}
              aria-label="List view"
            >
              <List className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
      
      {/* FilterBar Component */}
      <div className="mb-8">
        <FilterBar 
          onFilter={handleFilter} 
          views={bookmarkedViews} 
          isLoading={isLoading} 
          initialFilters={filters}
        />
      </div>

      {/* Top Pagination */}
      {!isLoading && bookmarkedViews.length > 0 && (
        <div className="mb-8">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            perPage={perPage}
            totalItems={totalItems}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
          />
        </div>
      )}

      {/* Empty State */}
      {bookmarkedViews.length === 0 && (
        <div className="text-center py-20 bg-card border border-border rounded-lg">
          <Bookmark className="h-16 w-16 mx-auto text-muted-foreground mb-4" />
          <h2 className="text-2xl font-bold mb-2">No bookmarks yet</h2>
          <p className="text-muted-foreground mb-6">
            Save your favorite views by clicking the bookmark icon
          </p>
          <Link 
            to="/" 
            className="px-4 py-2 bg-primary text-primary-foreground rounded-md hover:bg-primary/90 transition-colors"
          >
            Browse Views
          </Link>
        </div>
      )}

      {/* View Grid/List */}
      <AnimatePresence mode="wait">
        <motion.div
          key={viewMode + isCompactMode + viewTransition}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {paginatedGroupedViews.map(({ range, views }) => 
            views.length > 0 && (
              <div key={range} className="mb-12">
                <div className="mb-8 relative flex items-center">
                  {/* Futuristic horizontal line with glow effect */}
                  <div className="absolute left-0 right-0 h-[1px] bg-gradient-to-r from-transparent via-primary/50 to-transparent top-1/2 -z-10" />
                  <div className="absolute left-0 right-0 h-[1px] blur-[2px] bg-gradient-to-r from-transparent via-primary/30 to-transparent top-1/2 -z-10" />
                  
                  {/* Pill shape with gradient background */}
                  <div className={cn(
                    "relative inline-flex items-center px-6 py-2.5 rounded-full shadow-lg border border-primary/20",
                    "bg-gradient-to-r",
                    range === '500+km' ? 'from-indigo-600/90 to-blue-600/90 dark:from-indigo-900/90 dark:to-blue-900/90' : 
                    range === '100-500km' ? 'from-blue-600/90 to-cyan-600/90 dark:from-blue-800/90 dark:to-cyan-800/90' : 
                    'from-cyan-600/90 to-teal-600/90 dark:from-cyan-800/90 dark:to-teal-800/90'
                  )}>
                    {/* Subtle glow effect */}
                    <div className={cn(
                      "absolute inset-0 rounded-full blur-md opacity-50 -z-10",
                      range === '500+km' ? 'bg-indigo-600' : 
                      range === '100-500km' ? 'bg-blue-600' : 
                      'bg-cyan-600'
                    )} />
                    
                    {/* Small decorative dots */}
                    <div className="absolute left-0 top-0 w-full h-full">
                      <div className="absolute w-1 h-1 rounded-full bg-white/40 left-2 top-[50%] -translate-y-1/2" />
                      <div className="absolute w-0.5 h-0.5 rounded-full bg-white/40 left-4 top-[35%]" />
                      <div className="absolute w-0.5 h-0.5 rounded-full bg-white/40 left-3 top-[65%]" />
                    </div>
                    
                    {/* Range text with futuristic styling */}
                    <h2 className="text-xl font-bold text-white tracking-wide">
                      {range}
                    </h2>
                  </div>
                  
                  {/* Decorative elements after the pill */}
                  <div className="ml-4 flex items-center">
                    <div className="w-2 h-2 rounded-full bg-primary/70" />
                    <div className="w-8 h-[1px] bg-gradient-to-r from-primary/70 to-transparent" />
                    <div className="w-1 h-1 rounded-full bg-primary/50" />
                    <div className="w-12 h-[1px] bg-gradient-to-r from-primary/50 to-transparent" />
                  </div>
                  
                  {/* Decorative ending elements */}
                  <div className="flex-1 ml-4">
                    <div className="h-[1px] w-full bg-gradient-to-r from-primary/30 to-transparent" />
                  </div>
                </div>
                
                <div className={`${viewMode === 'list' ? 'space-y-4' : 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6'}`}>
                  {views.map(view => (
                    <ViewCard
                      key={view.id}
                      view={view}
                      onClick={() => setSelectedView(view)}
                      isBookmarked={isBookmarked(view.id)}
                      onBookmarkToggle={(e) => {
                        e.stopPropagation();
                        toggleBookmark(view.id);
                      }}
                      onShare={(e) => {
                        e.stopPropagation();
                        handleShare(view);
                      }}
                      isCompact={isCompactMode}
                      isList={viewMode === 'list'}
                    />
                  ))}
                </div>
              </div>
            )
          )}
        </motion.div>
      </AnimatePresence>

      {/* Bottom Pagination */}
      {!isLoading && bookmarkedViews.length > 0 && (
        <div className="mt-8">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            perPage={perPage}
            totalItems={totalItems}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
          />
        </div>
      )}
      
    </main>
  );
}

export default App;