import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import type { View } from '../../types';
import { ViewPageSEO } from './SEO';
import { Skeleton } from '../../components/ui/skeleton';
import { Button } from '../../components/ui/button';
import { Share2, ArrowLeft, Bookmark, MapPin } from 'lucide-react';
import { cn } from '../../lib/utils';

export default function ViewPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [view, setView] = useState<View | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  
  useEffect(() => {
    // Fetch view data based on the ID
    const fetchView = async () => {
      setLoading(true);
      try {
        // This would be replaced with an actual API call
        // For now, we'll simulate a response
        const response = await fetch(`/api/views/${id}`);
        if (!response.ok) {
          throw new Error('View not found');
        }
        const data = await response.json();
        setView(data);
      } catch (err) {
        console.error('Error fetching view:', err);
        setError('Could not load the view. It might not exist or there was a network error.');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchView();
    }
  }, [id]);

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <div className="container mx-auto py-8 px-4 max-w-5xl">
        <button 
          type="button"
          onClick={handleBack} 
          className="flex items-center text-foreground hover:text-primary mb-4 transition"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Back
        </button>
        <Skeleton className="h-96 w-full rounded-lg mb-6" />
        <Skeleton className="h-10 w-1/2 mb-4" />
        <Skeleton className="h-6 w-3/4 mb-2" />
        <Skeleton className="h-6 w-2/3 mb-6" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <Skeleton className="h-8 w-1/3 mb-4" />
            <Skeleton className="h-6 w-full mb-2" />
            <Skeleton className="h-6 w-full mb-2" />
            <Skeleton className="h-6 w-2/3" />
          </div>
          <div>
            <Skeleton className="h-8 w-1/3 mb-4" />
            <Skeleton className="h-6 w-full mb-2" />
            <Skeleton className="h-6 w-full mb-2" />
            <Skeleton className="h-6 w-2/3" />
          </div>
        </div>
      </div>
    );
  }

  if (error || !view) {
    return (
      <div className="container mx-auto py-8 px-4 max-w-5xl">
        <button 
          type="button"
          onClick={handleBack} 
          className="flex items-center text-foreground hover:text-primary mb-4 transition"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Back
        </button>
        <div className="bg-card border border-border rounded-lg p-8 text-center">
          <h1 className="text-2xl font-bold mb-4 text-foreground">View Not Found</h1>
          <p className="text-muted-foreground mb-6">{error || "We couldn't find the view you're looking for."}</p>
          <button
            type="button"
            onClick={handleBack}
            className="px-4 py-2 bg-primary text-primary-foreground rounded-md hover:bg-primary/90 transition"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <ViewPageSEO view={view} />
      <div className="container mx-auto py-8 px-4 max-w-5xl">
        <button 
          type="button"
          onClick={handleBack} 
          className="flex items-center text-foreground hover:text-primary mb-4 transition"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Back
        </button>
        
        <div className="mb-8">
          <img 
            src={view.image || '/placeholder-view.jpg'} 
            alt={`View from ${view.source} to ${view.destination}`}
            className="w-full h-[500px] object-cover rounded-lg shadow-md mb-4"
          />
          
          <div className="flex justify-between items-start">
            <div>
              <h1 className="text-3xl font-bold gradient-text mb-2">
                {view.source} to {view.destination}
              </h1>
              <p className="text-lg text-muted-foreground mb-2">
                {view.distance} • <span className={cn(
                  "px-2 py-0.5 rounded-full text-sm",
                  view.status === 'confirmed' && "bg-green-200/20 text-green-600",
                  view.status === 'likely' && "bg-blue-200/20 text-blue-600",
                  view.status === 'ifclear' && "bg-yellow-200/20 text-yellow-600",
                  view.status === 'plausible' && "bg-purple-200/20 text-purple-600",
                  view.status === 'dubious' && "bg-orange-200/20 text-orange-600",
                  view.status === 'unlikely' && "bg-red-200/20 text-red-600"
                )}>
                  {view.status.charAt(0).toUpperCase() + view.status.slice(1)}
                </span>
              </p>
            </div>
            
            <div className="flex space-x-2">
              <button 
                type="button" 
                className="inline-flex items-center px-3 py-1 border border-input bg-background hover:bg-muted/50 text-foreground rounded-md text-xs"
              >
                <Bookmark className="h-4 w-4 mr-2" />
                Save
              </button>
              <button 
                type="button" 
                className="inline-flex items-center px-3 py-1 border border-input bg-background hover:bg-muted/50 text-foreground rounded-md text-xs"
              >
                <Share2 className="h-4 w-4 mr-2" />
                Share
              </button>
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
          <div className="bg-card border border-border rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4 flex items-center text-primary">
              <MapPin className="mr-2 h-5 w-5" />
              Source Location
            </h2>
            <p className="text-foreground mb-1">
              <span className="font-medium">Name:</span> {view.source}
            </p>
            <p className="text-foreground mb-1">
              <span className="font-medium">State/Province:</span> {view.state}
            </p>
            <p className="text-foreground mb-1">
              <span className="font-medium">Country:</span> {view.country}
            </p>
            <p className="text-foreground">
              <span className="font-medium">Continent:</span> {view.continent}
            </p>
          </div>
          
          <div className="bg-card border border-border rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4 flex items-center text-primary">
              <MapPin className="mr-2 h-5 w-5" />
              Destination Location
            </h2>
            <p className="text-foreground mb-1">
              <span className="font-medium">Name:</span> {view.destination}
            </p>
            <p className="text-foreground mb-1">
              <span className="font-medium">State/Province:</span> {view.destinationState}
            </p>
            <p className="text-foreground mb-1">
              <span className="font-medium">Country:</span> {view.destinationCountry || 'N/A'}
            </p>
            <p className="text-foreground">
              <span className="font-medium">Continent:</span> {view.destinationContinent}
            </p>
          </div>
        </div>
        
        <div className="bg-card border border-border rounded-lg p-6 mb-8">
          <h2 className="text-xl font-semibold mb-4 text-primary">Description</h2>
          <p className="text-foreground">
            {/* This would be populated from the API */}
            This is a remarkable line-of-sight view from {view.source} to {view.destination}, spanning a distance of {view.distance}. On clear days, the visibility allows you to see the distinctive features of {view.destination} from {view.source}.
          </p>
        </div>
        
        <div className="bg-card border border-border rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4 text-primary">Map View</h2>
          <div className="bg-muted h-80 rounded-lg flex items-center justify-center">
            <p className="text-muted-foreground">
              Interactive map coming soon
            </p>
          </div>
        </div>
      </div>
    </>
  );
} 