import React from 'react';
import { SEO } from '../../lib/seo';

export function ContactPageSEO() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "name": "Contact TheViewShed",
    "description": "Get in touch with the TheViewShed team for questions, feedback, data contributions, or partnership inquiries.",
    "url": "https://theviewshed.com/contact"
  };

  return (
    <SEO
      title="Contact Us | TheViewShed"
      description="Have questions or feedback about TheViewShed? Want to contribute data or discuss partnerships? Get in touch with our team through our contact form."
      keywords="contact TheViewShed, viewshed feedback, contribute data, report issues, partnership inquiries"
      structuredData={structuredData}
    />
  );
} 