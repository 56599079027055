import type { ApiSuccessResponse, ApiErrorResponse } from '../../lib/apiHandler';

// Interface for the expected contact form data
export interface ContactFormData {
  name: string;
  email: string;
  subject: string;
  message: string;
}

/**
 * API endpoint for sending contact form emails
 */
export async function sendContactEmail(formData: ContactFormData): Promise<ApiSuccessResponse | ApiErrorResponse> {
  try {
    const { name, email, subject, message } = formData;
    
    // Validate the form data
    if (!name || !email || !subject || !message) {
      return { 
        success: false as const, 
        error: 'All fields are required' 
      };
    }
    
    // Use DirectFlow's API to handle email sending
    const response = await fetch('https://directflow.app/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name,
        email,
        subject,
        message
      }),
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      return { 
        success: false as const, 
        error: errorData.error || 'Error sending email' 
      };
    }
    
    const data = await response.json();
    return { 
      success: true as const, 
      data 
    };
  } catch (error) {
    console.error('Exception sending email:', error);
    return { 
      success: false as const, 
      error: error instanceof Error ? error.message : 'An unknown error occurred' 
    };
  }
} 