import { sendContactEmail } from '../pages/api/contact';
import type { ContactFormData } from '../pages/api/contact';

// Define common response types
export interface ApiSuccessResponse<T = unknown> {
  success: true;
  data: T;
}

export interface ApiErrorResponse {
  success: false;
  error: string;
}

export type ApiResponse<T = unknown> = ApiSuccessResponse<T> | ApiErrorResponse;

/**
 * This is a client-side API handler for use in a Vite/React application.
 * In a production environment, these API endpoints would typically be 
 * server-side routes to protect sensitive information like API keys.
 */

/**
 * Handle API requests to different endpoints
 */
export async function handleApiRequest(
  endpoint: string, 
  method: string, 
  data: Record<string, unknown>
): Promise<ApiResponse> {
  // Validate the request
  if (!endpoint || !method) {
    return { 
      success: false as const, 
      error: 'Invalid request' 
    };
  }
  
  // Route to the appropriate handler based on the endpoint
  switch (endpoint) {
    case '/api/contact':
      if (method === 'POST') {
        // Type validation before passing to the handler
        const contactData = data as Record<string, unknown>;
        // Check if the data has the required fields
        if (
          typeof contactData.name === 'string' &&
          typeof contactData.email === 'string' &&
          typeof contactData.subject === 'string' &&
          typeof contactData.message === 'string'
        ) {
          const result = await sendContactEmail({
            name: contactData.name,
            email: contactData.email,
            subject: contactData.subject,
            message: contactData.message
          });
          
          if (result.success) {
            return {
              success: true as const,
              data: result.data
            };
          }
          
          return {
            success: false as const,
            error: result.error || 'Unknown error sending email'
          };
        }
        
        return {
          success: false as const,
          error: 'Invalid contact form data'
        };
      }
      break;
    default:
      return { 
        success: false as const, 
        error: `Unknown endpoint: ${endpoint}` 
      };
  }
  
  return { 
    success: false as const, 
    error: `Method ${method} not supported for ${endpoint}` 
  };
} 