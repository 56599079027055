import React from 'react';
import { SEO } from '../../lib/seo';

export function SubmitPageSEO() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Submit a View | TheViewShed",
    "description": "Contribute to TheViewShed by submitting a line-of-sight view you've discovered. Help grow our community directory of remarkable vistas and viewpoints.",
    "url": "https://theviewshed.com/submit",
    "mainEntity": {
      "@type": "WebForm",
      "name": "View Submission Form",
      "description": "Form to submit a new line-of-sight view to TheViewShed directory"
    }
  };

  return (
    <SEO
      title="Submit a View | TheViewShed"
      description="Contribute to our community-driven directory by submitting a line-of-sight view you've discovered. Share remarkable viewpoints and help others find breathtaking vistas."
      keywords="submit view, contribute to viewshed, add line of sight, share viewpoint, submit vista, contribute to directory"
      structuredData={structuredData}
    />
  );
} 