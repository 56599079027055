import React from 'react';

export function ViewSkeleton() {
  return (
    <div className="rounded-lg shadow-md overflow-hidden border border-border bg-card animate-pulse">
      <div className="h-48 bg-muted/50"></div>
      <div className="p-4 space-y-3">
        <div className="h-4 bg-muted/50 rounded w-1/3"></div>
        <div className="space-y-2">
          <div className="h-3 bg-muted/50 rounded w-full"></div>
          <div className="h-3 bg-muted/50 rounded w-5/6"></div>
          <div className="h-3 bg-muted/50 rounded w-4/6"></div>
          <div className="h-3 bg-muted/50 rounded w-full"></div>
          <div className="h-3 bg-muted/50 rounded w-3/4"></div>
        </div>
      </div>
    </div>
  );
} 