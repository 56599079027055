import React from 'react';
import { Helmet } from 'react-helmet-async';

export interface SEOProps {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  url?: string;
  type?: 'website' | 'article';
  publishedAt?: string;
  modifiedAt?: string;
  author?: string;
  structuredData?: Record<string, unknown>;
  children?: React.ReactNode;
}

export function SEO({
  title = 'TheViewShed - Line-of-Sight Views Directory',
  description = 'A community driven visual directory of line-of-sight views from around the world. Discover and share remarkable vistas, viewpoints, and visual connections between landmarks.',
  keywords = 'viewshed, line of sight, views, vistas, mountain views, panorama, landscape, viewpoints, distance views, visual connections, landmarks',
  image = '/og-image.jpg',
  url = typeof window !== 'undefined' ? window.location.href : '',
  type = 'website',
  publishedAt,
  modifiedAt,
  author = 'TheViewShed Community',
  structuredData,
  children,
}: SEOProps) {
  // Use current URL if not specified
  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
  const pageUrl = url || currentUrl;
  
  // Format canonical URL properly
  const canonicalUrl = pageUrl.endsWith('/') ? pageUrl : `${pageUrl}/`;
  
  // Construct absolute image URL if not already absolute
  const absoluteImageUrl = image.startsWith('http') 
    ? image 
    : `${typeof window !== 'undefined' ? window.location.origin : ''}${image}`;

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={canonicalUrl} />
      
      {/* OpenGraph Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:image" content={absoluteImageUrl} />
      <meta property="og:site_name" content="TheViewShed" />
      
      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={absoluteImageUrl} />
      
      {/* Article specific meta tags */}
      {type === 'article' && publishedAt && (
        <meta property="article:published_time" content={publishedAt} />
      )}
      {type === 'article' && modifiedAt && (
        <meta property="article:modified_time" content={modifiedAt} />
      )}
      {type === 'article' && author && (
        <meta property="article:author" content={author} />
      )}
      
      {/* Structured Data for SEO */}
      {structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}
      
      {/* Allow for additional custom tags */}
      {children}
    </Helmet>
  );
} 