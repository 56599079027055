'use client';

import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '../../lib/utils';
import { ChevronDown } from 'lucide-react';
import { FAQPageSEO } from './SEO';

interface FAQItem {
  id: string;
  question: string;
  answer: React.ReactNode;
  category: 'general' | 'submissions' | 'technical' | 'about';
}

const faqs: FAQItem[] = [
  {
    id: '1',
    question: 'What is TheViewShed?',
    category: 'about',
    answer: (
      <div className="space-y-3">
        <p>TheViewShed is a community driven visual directory of line-of-sight views from around the world.</p>
        
        <p>The <strong>source</strong> must be from the ground on Earth (including on hills and mountains) or from a man-made structure attached to the ground.</p>
        
        <p>The <strong>destination</strong> can be just about anything one can see from the source (that is also ground or attached to the ground on Earth).</p>
        
        <div className="bg-muted/40 p-3 rounded-md border border-border">
          <h4 className="font-medium mb-2 text-primary">Key requirements:</h4>
          <ul className="list-disc pl-5 space-y-1">
            <li>Photos or view-claims cannot be from planes, drones, hot air balloons, rockets or space stations.</li>
            <li>Celestial objects (like Mars) are not included, despite being visible.</li>
            <li>The distance must be <strong>over 10 kilometres</strong> (~6.2 miles).</li>
          </ul>
        </div>
      </div>
    )
  },
  {
    id: '2', 
    question: 'How do I submit my own view?',
    category: 'submissions',
    answer: (
      <div className="space-y-3">
        <p>You can submit your own view by following these steps:</p>
        
        <ol className="list-decimal pl-5 space-y-2">
          <li>Navigate to our <a href="/submit" className="text-primary hover:underline">Submit A View</a> page</li>
          <li>Fill out the required form fields with accurate information</li>
          <li>Upload a clear image showing the claimed view</li>
          <li>Add your contact details if you'd like to be notified when published</li>
          <li>Submit the form</li>
        </ol>
        
        <div className="bg-primary/10 p-3 rounded-md border border-primary/20 flex items-start gap-2 mt-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-5 w-5 text-primary shrink-0 mt-0.5">
            <circle cx="12" cy="12" r="10"/>
            <line x1="12" y1="8" x2="12" y2="12"/>
            <line x1="12" y1="16" x2="12.01" y2="16"/>
          </svg>
          <p className="text-sm">Please ensure your image clearly shows both the source and destination locations for faster review.</p>
        </div>
      </div>
    )
  },
  {
    id: '3',
    question: 'How long will it take for my view to show on the site?',
    category: 'submissions',
    answer: (
      <div className="space-y-3">
        <p>Please allow up to <strong>48 hours</strong> for your record to be reviewed by an admin and published.</p>
        
        <p>You'll be notified once this is done if you've provided an email address.</p>
        
        <div className="flex items-start gap-3 mt-1 p-3 rounded-md border bg-muted/30">
          <div className="bg-primary/20 rounded-md p-2 text-primary font-medium">
            Review process:
          </div>
          <div className="grid grid-cols-3 gap-2 text-xs w-full">
            <div className="rounded bg-muted/50 p-2 text-center">
              <div className="font-medium">Submission</div>
              <div className="text-muted-foreground">Day 0</div>
            </div>
            <div className="rounded bg-muted/50 p-2 text-center">
              <div className="font-medium">Review</div>
              <div className="text-muted-foreground">1-2 days</div>
            </div>
            <div className="rounded bg-muted/50 p-2 text-center">
              <div className="font-medium">Publication</div>
              <div className="text-muted-foreground">When approved</div>
            </div>
          </div>
        </div>
      </div>
    )
  },
  {
    id: '4',
    question: `One of your images is wrong, it doesn't show what you're claiming it does!`,
    category: 'technical',
    answer: (
      <div className="space-y-3">
        <p>Are you sure? Look a bit closer (somewhere in the back) and you might find what you're after. It's not always obvious.</p>
        
        <p>If you're absolutely sure there's an error, please submit a feedback form through our <a href="/contact" className="text-primary hover:underline">Contact page</a>.</p>
        
        <div className="bg-muted/40 p-3 rounded-md border border-border mt-2">
          <h4 className="font-medium mb-2">Common misunderstandings:</h4>
          <ul className="list-disc pl-5 space-y-1">
            <li>The destination might be very small in the image due to distance</li>
            <li>Atmospheric conditions can make distant objects appear differently</li>
            <li>Perspective can sometimes be confusing in photographs</li>
          </ul>
        </div>
      </div>
    )
  },
  {
    id: '5',
    question: 'What do the Status\' mean?',
    category: 'technical',
    answer: (
      <div className="space-y-4">
        <p>Each view is assigned a status, based on likelihood that the view is possible:</p>
        
        <div className="grid gap-3">
          <div className="flex items-start gap-3 p-3 rounded-md bg-card border border-border">
            <div className="w-3 h-3 mt-1.5 rounded-full bg-green-500 flex-shrink-0"></div>
            <div>
              <h4 className="font-semibold text-primary">Confirmed</h4>
              <p className="text-muted-foreground">100% possible, almost always confirmed by an image.</p>
            </div>
          </div>
          
          <div className="flex items-start gap-3 p-3 rounded-md bg-card border border-border">
            <div className="w-3 h-3 mt-1.5 rounded-full bg-blue-500 flex-shrink-0"></div>
            <div>
              <h4 className="font-semibold text-primary">Likely</h4>
              <p className="text-muted-foreground">Not confirmed, but very likely to be broadly possible based on distance and environmental factors.</p>
            </div>
          </div>
          
          <div className="flex items-start gap-3 p-3 rounded-md bg-card border border-border">
            <div className="w-3 h-3 mt-1.5 rounded-full bg-cyan-500 flex-shrink-0"></div>
            <div>
              <h4 className="font-semibold text-primary">If Clear</h4>
              <p className="text-muted-foreground">Likely possible, but only under ideal weather conditions.</p>
            </div>
          </div>
          
          <div className="flex items-start gap-3 p-3 rounded-md bg-card border border-border">
            <div className="w-3 h-3 mt-1.5 rounded-full bg-yellow-500 flex-shrink-0"></div>
            <div>
              <h4 className="font-semibold text-primary">Plausible</h4>
              <p className="text-muted-foreground">Under the right conditions this view could be possible, but more evidence is required.</p>
            </div>
          </div>
          
          <div className="flex items-start gap-3 p-3 rounded-md bg-card border border-border">
            <div className="w-3 h-3 mt-1.5 rounded-full bg-orange-500 flex-shrink-0"></div>
            <div>
              <h4 className="font-semibold text-primary">Dubious</h4>
              <p className="text-muted-foreground">This view is probably mathematically possible, but the conditions required to confirm it might never happen.</p>
            </div>
          </div>
          
          <div className="flex items-start gap-3 p-3 rounded-md bg-card border border-border">
            <div className="w-3 h-3 mt-1.5 rounded-full bg-red-500 flex-shrink-0"></div>
            <div>
              <h4 className="font-semibold text-primary">Unlikely</h4>
              <p className="text-muted-foreground">Almost or simply outright impossible (destination is obstructed by something else or is below the horizon), even under ideal conditions.</p>
            </div>
          </div>
        </div>
      </div>
    )
  },
  {
    id: '6',
    question: 'Some of the information is wrong, how do I notify you?',
    category: 'general',
    answer: (
      <div className="space-y-3">
        <p>We're mostly human, so we make mistakes. If you think you've identified incorrect information on one of our views, please submit some feedback.</p>
        
        <div className="bg-muted/40 p-3 rounded-md border border-border">
          <h4 className="font-medium mb-2">To report an issue:</h4>
          <ol className="list-decimal pl-5 space-y-1">
            <li>Visit our <a href="/contact" className="text-primary hover:underline">Contact page</a></li>
            <li>Specify the view ID or URL</li>
            <li>Explain what information you believe is incorrect</li>
            <li>Provide evidence if available</li>
          </ol>
        </div>
        
        <p className="text-sm text-muted-foreground">We appreciate your help in maintaining accuracy across our database.</p>
      </div>
    )
  },
  {
    id: '7',
    question: 'Is everything on TheViewShed free?',
    category: 'about',
    answer: (
      <div className="space-y-3">
        <p>For you, the user, <strong>yes</strong>.</p>
        
        <p>But not for us. We are a team with a particular passion for this topic, and manage the site in our spare time.</p>
        
        <div className="bg-primary/10 p-3 rounded-md border border-primary/20">
          <p>Please consider donating any amount you think is appropriate to help us maintain and build this project.</p>
          
          <div className="mt-3">
            <a 
              href="#donate" 
              className="inline-block px-4 py-2 bg-primary text-primary-foreground rounded-md hover:bg-primary/90 transition-colors"
            >
              Support TheViewShed
            </a>
          </div>
        </div>
      </div>
    )
  },
  {
    id: '8',
    question: `My cousin told me the Earth is flat, are you saying he's wrong?!`,
    category: 'general',
    answer: (
      <div className="space-y-3">
        <p><em>*sigh*</em> You might be on the wrong website.</p>
        
        <div className="bg-muted/40 p-3 rounded-md border border-border">
          <p className="italic">TheViewShed is based on the scientific understanding that the Earth is an oblate spheroid. Our distance calculations take into account the curvature of the Earth.</p>
        </div>
        
        <p className="text-xs text-muted-foreground">But hey, if you're interested in learning about actual line-of-sight views from around our beautiful <strong>round</strong> planet, you're in the right place!</p>
      </div>
    )
  }
];

export default function FAQPage() {
  const [openItem, setOpenItem] = useState<string>('1');
  
  const toggleItem = (id: string) => {
    setOpenItem(id === openItem ? '' : id);
  };

  return (
    <>
      <FAQPageSEO />
      <div className="container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-3xl font-bold mb-8 gradient-text">Frequently Asked Questions</h1>
        
        {/* FAQ items */}
        <div className="space-y-4">
          {faqs.map((faq) => (
            <div key={faq.id} className="border border-border rounded-lg overflow-hidden bg-card shadow-sm">
              <button
                type="button"
                onClick={() => toggleItem(faq.id)}
                className={cn(
                  "w-full px-6 py-4 text-left flex justify-between items-center",
                  "hover:bg-muted/50 transition-colors duration-150",
                  openItem === faq.id && "bg-muted/50"
                )}
                aria-expanded={openItem === faq.id}
                aria-controls={`faq-answer-${faq.id}`}
              >
                <span className="font-medium text-card-foreground">{faq.question}</span>
                <ChevronDown className={cn(
                  "h-5 w-5 text-primary transition-transform duration-200",
                  openItem === faq.id && "transform rotate-180"
                )} />
              </button>
              
              <AnimatePresence>
                {openItem === faq.id && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    id={`faq-answer-${faq.id}`}
                    className="overflow-hidden"
                  >
                    <div className="px-6 py-4 bg-muted/20 border-t border-border">
                      <div className="prose prose-sm dark:prose-invert max-w-none prose-headings:text-primary prose-a:text-primary">
                        {faq.answer}
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
        
        {/* Still have questions */}
        <div className="mt-12 p-6 bg-muted/30 border border-border rounded-lg text-center">
          <h2 className="text-xl font-bold mb-2">Still have questions?</h2>
          <p className="text-muted-foreground mb-4">We're here to help. Reach out to us directly.</p>
          <a 
            href="/contact" 
            className="inline-block px-4 py-2 bg-primary text-primary-foreground rounded-md hover:bg-primary/90 transition-colors"
          >
            Contact Us
          </a>
        </div>
      </div>
    </>
  );
}
