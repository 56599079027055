import { useState } from 'react';
import { Mountain, Upload, Info } from 'lucide-react';
import { useToast } from '../../components/ui/toast';
import { submitView } from '../../lib/api';
import { SubmitPageSEO } from './SEO';

interface FormData {
  sourceName: string;
  sourceState: string;
  sourceCountry: string;
  destinationName: string;
  destinationState: string;
  destinationCountry: string;
  distance: string;
  distanceUnit: 'km' | 'mi';
  status: 'confirmed' | 'likely' | 'if-clear' | 'plausible' | 'dubious' | 'unlikely';
  description: string;
  email: string;
  submitterName: string;
  creditInfo: string;
  creditName: string;
  imageFile: File | null;
  coordinates: {
    sourceLat: string;
    sourceLong: string;
    destinationLat: string;
    destinationLong: string;
  };
}

export default function SubmitViewPage() {
  const { toast } = useToast();
  const [formData, setFormData] = useState<FormData>({
    sourceName: '',
    sourceState: '',
    sourceCountry: '',
    destinationName: '',
    destinationState: '',
    destinationCountry: '',
    distance: '',
    distanceUnit: 'km',
    status: 'confirmed',
    description: '',
    email: '',
    submitterName: '',
    creditInfo: '',
    creditName: '',
    imageFile: null,
    coordinates: {
      sourceLat: '',
      sourceLong: '',
      destinationLat: '',
      destinationLong: '',
    }
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => {
        if (parent === 'coordinates') {
          return {
            ...prev,
            coordinates: {
              ...prev.coordinates,
              [child]: value
            }
          };
        }
        return prev;
      });
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];
      setFormData(prev => ({
        ...prev,
        imageFile: file
      }));

      // Create preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      // Transform form data to match API expectations
      const apiFormData = {
        source: formData.sourceName,
        state: formData.sourceState,
        country: formData.sourceCountry,
        destination: formData.destinationName,
        dest_state: formData.destinationState,
        dest_country: formData.destinationCountry,
        distance_km: formData.distanceUnit === 'km' ? formData.distance : (Number(formData.distance) * 1.60934).toFixed(2),
        distance_m: formData.distanceUnit === 'mi' ? formData.distance : (Number(formData.distance) * 0.621371).toFixed(2),
        status: formData.status,
        type: 'Mountain(s)',
        source_lat: formData.coordinates.sourceLat,
        source_long: formData.coordinates.sourceLong,
        dest_lat: formData.coordinates.destinationLat,
        dest_long: formData.coordinates.destinationLong,
        description: formData.description,
        email: formData.email,
        submitterName: formData.submitterName,
        creditInfo: formData.creditInfo,
        creditName: formData.creditName,
        // Image handling would typically involve uploading to a storage service
        // and then storing the URL, but we're just simulating that here
        resource_2: previewUrl
      };
      
      const success = await submitView(apiFormData);
      
      if (success) {
        toast({
          title: "Submission received!",
          description: "Your view has been submitted for review. We'll notify you once it's approved."
        });
        
        // Reset form
        setFormData({
          sourceName: '',
          sourceState: '',
          sourceCountry: '',
          destinationName: '',
          destinationState: '',
          destinationCountry: '',
          distance: '',
          distanceUnit: 'km',
          status: 'confirmed',
          description: '',
          email: '',
          submitterName: '',
          creditInfo: '',
          creditName: '',
          imageFile: null,
          coordinates: {
            sourceLat: '',
            sourceLong: '',
            destinationLat: '',
            destinationLong: '',
          }
        });
        setPreviewUrl(null);
      } else {
        toast({
          title: "Submission failed",
          description: "There was an error submitting your view. Please try again."
        });
      }
    } catch (error) {
      console.error('Error submitting view:', error);
      toast({
        title: "Submission failed",
        description: "There was an error submitting your view. Please try again."
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <SubmitPageSEO />
      <div className="container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-3xl font-bold mb-2 gradient-text">Submit a View</h1>
        <p className="text-foreground mb-8">Share your spectacular line-of-sight view with the community.</p>
        
        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Source Location */}
          <div className="bg-card border border-border rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4 flex items-center text-primary">
              <Mountain className="mr-2 h-5 w-5" />
              Source Location
            </h2>
            <p className="text-muted-foreground mb-4 text-sm">This is the location from where the view was observed.</p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="sourceName" className="block text-sm font-medium mb-1">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="sourceName"
                  name="sourceName"
                  value={formData.sourceName}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="e.g. Mount Rainier"
                />
              </div>
              
              <div>
                <label htmlFor="sourceState" className="block text-sm font-medium mb-1">
                  State/Province <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="sourceState"
                  name="sourceState"
                  value={formData.sourceState}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="e.g. Washington"
                />
              </div>
              
              <div>
                <label htmlFor="sourceCountry" className="block text-sm font-medium mb-1">
                  Country <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="sourceCountry"
                  name="sourceCountry"
                  value={formData.sourceCountry}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="e.g. United States"
                />
              </div>
              
              <div className="md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="coordinates.sourceLat" className="block text-sm font-medium mb-1">
                    Latitude
                  </label>
                  <input
                    type="text"
                    id="coordinates.sourceLat"
                    name="coordinates.sourceLat"
                    value={formData.coordinates.sourceLat}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                    placeholder="e.g. 47.8021"
                  />
                </div>
                
                <div>
                  <label htmlFor="coordinates.sourceLong" className="block text-sm font-medium mb-1">
                    Longitude
                  </label>
                  <input
                    type="text"
                    id="coordinates.sourceLong"
                    name="coordinates.sourceLong"
                    value={formData.coordinates.sourceLong}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                    placeholder="e.g. -123.7068"
                  />
                </div>
              </div>
            </div>
          </div>
          
          {/* Destination Location */}
          <div className="bg-card border border-border rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4 flex items-center text-primary">
              <Mountain className="mr-2 h-5 w-5" />
              Destination Location
            </h2>
            <p className="text-muted-foreground mb-4 text-sm">This is the distant location that was visible from the source.</p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="destinationName" className="block text-sm font-medium mb-1">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="destinationName"
                  name="destinationName"
                  value={formData.destinationName}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="e.g. Mount Baker"
                />
              </div>
              
              <div>
                <label htmlFor="destinationState" className="block text-sm font-medium mb-1">
                  State/Province <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="destinationState"
                  name="destinationState"
                  value={formData.destinationState}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="e.g. Washington"
                />
              </div>
              
              <div>
                <label htmlFor="destinationCountry" className="block text-sm font-medium mb-1">
                  Country <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="destinationCountry"
                  name="destinationCountry"
                  value={formData.destinationCountry}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="e.g. United States"
                />
              </div>
              
              <div className="md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="coordinates.destinationLat" className="block text-sm font-medium mb-1">
                    Latitude
                  </label>
                  <input
                    type="text"
                    id="coordinates.destinationLat"
                    name="coordinates.destinationLat"
                    value={formData.coordinates.destinationLat}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                    placeholder="e.g. 48.7768"
                  />
                </div>
                
                <div>
                  <label htmlFor="coordinates.destinationLong" className="block text-sm font-medium mb-1">
                    Longitude
                  </label>
                  <input
                    type="text"
                    id="coordinates.destinationLong"
                    name="coordinates.destinationLong"
                    value={formData.coordinates.destinationLong}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                    placeholder="e.g. -121.8145"
                  />
                </div>
              </div>
            </div>
          </div>
          
          {/* View Details */}
          <div className="bg-card border border-border rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4 flex items-center text-primary">
              <Info className="mr-2 h-5 w-5" />
              View Details
            </h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label htmlFor="distance" className="block text-sm font-medium mb-1">
                    Distance <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    id="distance"
                    name="distance"
                    value={formData.distance}
                    onChange={handleInputChange}
                    required
                    min="10"
                    step="0.01"
                    className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                    placeholder="e.g. 120.5"
                  />
                </div>
                
                <div className="w-24">
                  <label htmlFor="distanceUnit" className="block text-sm font-medium mb-1">
                    Unit
                  </label>
                  <select
                    id="distanceUnit"
                    name="distanceUnit"
                    value={formData.distanceUnit}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  >
                    <option value="km">km</option>
                    <option value="mi">mi</option>
                  </select>
                </div>
              </div>
              
              <div>
                <label htmlFor="status" className="block text-sm font-medium mb-1">
                  Status <span className="text-red-500">*</span>
                </label>
                <select
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                >
                  <option value="confirmed">Confirmed</option>
                  <option value="likely">Likely</option>
                  <option value="if-clear">If Clear</option>
                  <option value="plausible">Plausible</option>
                  <option value="dubious">Dubious</option>
                  <option value="unlikely">Unlikely</option>
                </select>
              </div>
              
              <div className="md:col-span-2">
                <label htmlFor="description" className="block text-sm font-medium mb-1">
                  Description
                </label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  rows={4}
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="Provide any additional details about this view..."
                />
              </div>
            </div>
          </div>
          
          {/* Image Upload */}
          <div className="bg-card border border-border rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4 flex items-center text-primary">
              <Upload className="mr-2 h-5 w-5" />
              Image Upload
            </h2>
            <p className="text-muted-foreground mb-4 text-sm">Upload an image that shows the view. This helps verify the visibility claim.</p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="imageFile" className="block text-sm font-medium mb-2">
                  Upload Image
                </label>
                <input
                  type="file"
                  id="imageFile"
                  name="imageFile"
                  onChange={handleFileChange}
                  accept="image/*"
                  className="hidden"
                />
                <label
                  htmlFor="imageFile"
                  className="flex flex-col items-center justify-center w-full h-32 px-4 transition bg-background border-2 border-dashed border-input rounded-lg cursor-pointer hover:bg-muted/20"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <Upload className="w-8 h-8 mb-3 text-primary" />
                    <p className="mb-2 text-sm text-foreground">
                      <span className="font-semibold">Click to upload</span> or drag and drop
                    </p>
                    <p className="text-xs text-muted-foreground">
                      PNG, JPG or WEBP (MAX. 10MB)
                    </p>
                  </div>
                </label>
              </div>
              
              <div>
                {previewUrl ? (
                  <div className="h-full flex flex-col">
                    <p className="text-sm font-medium mb-2">Preview</p>
                    <div className="relative flex-1 border border-input rounded-lg overflow-hidden">
                      <img
                        src={previewUrl}
                        alt="View from source location to destination"
                        className="w-full h-full object-cover"
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setPreviewUrl(null);
                          setFormData(prev => ({ ...prev, imageFile: null }));
                        }}
                        className="absolute top-2 right-2 p-1 bg-background/80 rounded-full text-primary hover:bg-background"
                        aria-label="Remove image"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true">
                          <line x1="18" y1="6" x2="6" y2="18" />
                          <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="h-full flex items-center justify-center border border-input rounded-lg bg-background/50">
                    <p className="text-muted-foreground text-sm">Image preview will appear here</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          
          {/* Contact Information */}
          <div className="bg-card border border-border rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4 text-primary">Contact Information</h2>
            <p className="text-muted-foreground mb-4 text-sm">We'll notify you when your submission is approved.</p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label htmlFor="submitterName" className="block text-sm font-medium mb-1">
                  Submitter Name
                </label>
                <input
                  type="text"
                  id="submitterName"
                  name="submitterName"
                  value={formData.submitterName}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="Your name"
                />
              </div>
              
              <div>
                <label htmlFor="email" className="block text-sm font-medium mb-1">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="your.email@example.com"
                />
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="creditName" className="block text-sm font-medium mb-1">
                  Credit Name
                </label>
                <input
                  type="text"
                  id="creditName"
                  name="creditName"
                  value={formData.creditName}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="Name to credit for this view/photo"
                />
              </div>
              
              <div>
                <label htmlFor="creditInfo" className="block text-sm font-medium mb-1">
                  Credit Info / Link
                </label>
                <input
                  type="text"
                  id="creditInfo"
                  name="creditInfo"
                  value={formData.creditInfo}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="Website, social media, or other attribution link"
                />
              </div>
            </div>
          </div>
          
          {/* Submit Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-6 py-3 bg-primary text-primary-foreground rounded-md hover:bg-primary/90 transition-colors flex items-center disabled:opacity-70"
            >
              {isSubmitting ? (
                <>
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" aria-hidden="true">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                  Sending...
                </>
              ) : (
                <>
                  Submit View
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
} 