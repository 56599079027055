import { useState, useEffect, useMemo, useRef } from 'react';
import { Search, Filter, X, Save, Clock, ChevronDown, ChevronUp, MapPin, Flag, Globe, Mountain, Loader2 } from 'lucide-react';
import { cn } from '../lib/utils';
import type { View } from '../types';

// Export FilterState interface so it can be imported by other components
export interface FilterState {
  search: string | null;
  status: string[] | null;
  distance: string | null;
  sourceStates: string[];
  sourceCountries: string[];
  sourceContinent: string[];
  destinationStates: string[];
  destinationCountries: string[];
  destinationContinent: string[];
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  category: string[];
  seasons: string[];
  location?: string;
}

interface SavedFilter {
  id: string;
  name: string;
  filter: FilterState;
  createdAt: Date;
}

interface FilterBarProps {
  onFilter: (filters: FilterState) => void;
  views: View[];
  isLoading?: boolean;
  initialFilters?: FilterState;
}

const initialFilterState: FilterState = {
  search: null,
  status: ['confirmed'],
  distance: "50",
  sourceStates: [],
  sourceCountries: [],
  sourceContinent: [],
  destinationStates: [],
  destinationCountries: [],
  destinationContinent: [],
  sortBy: 'distance',
  sortDirection: 'desc',
  category: [],
  seasons: [],
  location: ''
};

export function FilterBar({ onFilter, views, isLoading = false, initialFilters }: FilterBarProps) {
  // Ensure confirmed status is always present in initial filters
  const safeInitialFilters = initialFilters ? {
    ...initialFilters,
    status: initialFilters.status?.length ? initialFilters.status : ['confirmed']
  } : initialFilterState;
  
  const [filters, setFilters] = useState<FilterState>(safeInitialFilters);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [savedFilters, setSavedFilters] = useState<SavedFilter[]>([]);
  const [filterName, setFilterName] = useState('');
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [activeFilterId, setActiveFilterId] = useState<string | null>(null);
  const [availableStatuses] = useState<string[]>(['confirmed', 'likely', 'ifclear', 'plausible', 'dubious', 'unlikely']);
  const [distanceRange, setDistanceRange] = useState<[number, number]>([0, 1000]);
  const [locationTab, setLocationTab] = useState('source');
  const [sourceSearchTerm, setSourceSearchTerm] = useState('');
  const [destSearchTerm, setDestSearchTerm] = useState('');
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [isApplyingFilter, setIsApplyingFilter] = useState(false);
  const isInitialMount = useRef(true);
  const filterPanelRef = useRef<HTMLDivElement>(null);
  const filterContainerRef = useRef<HTMLDivElement>(null);
  const isMobile = window.innerWidth < 768;
  
  // Apply initial filter only once on mount - using ref to prevent reapplication
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      
      // Apply initial filters without triggering state changes
      onFilter(initialFilters || initialFilterState);
    }
  }, [onFilter, initialFilters]);
  
  // Add click outside handler
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        isAdvancedOpen &&
        filterPanelRef.current && 
        filterContainerRef.current &&
        !filterPanelRef.current.contains(event.target as Node) &&
        !filterContainerRef.current.contains(event.target as Node)
      ) {
        setIsAdvancedOpen(false);
      }
    }
    
    // Only add the event listener if not on mobile
    if (!isMobile) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
    return undefined;
  }, [isAdvancedOpen, isMobile]);

  // Handle escape key to close panel
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape' && isAdvancedOpen) {
        setIsAdvancedOpen(false);
      }
    }
    
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isAdvancedOpen]);
  
  // Convert status to a human-readable label
  const getStatusLabel = (status: string): string => {
    const statusLabels: Record<string, string> = {
      'confirmed': 'Confirmed',
      'likely': 'Likely',
      'ifclear': 'If Clear',
      'plausible': 'Plausible',
      'dubious': 'Dubious',
      'unlikely': 'Unlikely'
    };
    return statusLabels[status] || status;
  };
  
  // Get the appropriate color for a status
  const getStatusColor = (status: string): string => {
    const statusColors: Record<string, string> = {
      'confirmed': 'bg-green-500',
      'likely': 'bg-blue-500',
      'ifclear': 'bg-cyan-500',
      'plausible': 'bg-yellow-500',
      'dubious': 'bg-orange-500',
      'unlikely': 'bg-red-500'
    };
    return statusColors[status] || 'bg-gray-500';
  };
  
  // Normalize status values for consistent comparison
  const normalizeStatus = (status: string): string => {
    // Handle if-clear format conversion
    if (status === 'if-clear') return 'ifclear';
    return status;
  };
  
  // Extract available filter options from views
  const {
    sourceStates,
    sourceCountries,
    sourceContinent,
    destinationStates,
    destinationCountries,
    destinationContinent
  } = useMemo(() => {
    const sourceStatesSet = new Set<string>();
    const sourceCountriesSet = new Set<string>();
    const sourceContinentSet = new Set<string>();
    const destinationStatesSet = new Set<string>();
    const destinationCountriesSet = new Set<string>();
    const destinationContinentSet = new Set<string>();
    
    for (const view of views) {
      // Source locations
      if (view.state) sourceStatesSet.add(view.state);
      if (view.country) sourceCountriesSet.add(view.country);
      if (view.continent) sourceContinentSet.add(view.continent);
      
      // Destination locations
      if (view.destinationState) destinationStatesSet.add(view.destinationState);
      if (view.destinationCountry) destinationCountriesSet.add(view.destinationCountry);
      if (view.destinationContinent) destinationContinentSet.add(view.destinationContinent);
    }
    
    return {
      sourceStates: Array.from(sourceStatesSet).sort(),
      sourceCountries: Array.from(sourceCountriesSet).sort(),
      sourceContinent: Array.from(sourceContinentSet).sort(),
      destinationStates: Array.from(destinationStatesSet).sort(),
      destinationCountries: Array.from(destinationCountriesSet).sort(),
      destinationContinent: Array.from(destinationContinentSet).sort()
    };
  }, [views]);
  
  // Filter source locations based on search term
  const filteredSourceLocations = useMemo(() => {
    const searchTerm = sourceSearchTerm.toLowerCase();
    return {
      states: sourceStates.filter(state => 
        !searchTerm || state.toLowerCase().includes(searchTerm)
      ),
      countries: sourceCountries.filter(country => 
        !searchTerm || country.toLowerCase().includes(searchTerm)
      ),
      continents: sourceContinent.filter(continent => 
        !searchTerm || continent.toLowerCase().includes(searchTerm)
      )
    };
  }, [sourceStates, sourceCountries, sourceContinent, sourceSearchTerm]);
  
  // Filter destination locations based on search term
  const filteredDestLocations = useMemo(() => {
    const searchTerm = destSearchTerm.toLowerCase();
    return {
      states: destinationStates.filter(state => 
        !searchTerm || state.toLowerCase().includes(searchTerm)
      ),
      countries: destinationCountries.filter(country => 
        !searchTerm || country.toLowerCase().includes(searchTerm)
      ),
      continents: destinationContinent.filter(continent => 
        !searchTerm || continent.toLowerCase().includes(searchTerm)
      )
    };
  }, [destinationStates, destinationCountries, destinationContinent, destSearchTerm]);
  
  // Calculate distance range from views
  useEffect(() => {
    if (views.length > 0) {
      const distances = views.map(view => {
        const distanceStr = view.distance.split(' ')[0];
        return Number.parseFloat(distanceStr);
      });
      
      const minDistance = Math.floor(Math.min(...distances));
      const maxDistance = Math.ceil(Math.max(...distances));
      setDistanceRange([minDistance, maxDistance]);
      
      // Initialize distance filter if not set
      if (!filters.distance) {
        setFilters(prev => ({
          ...prev,
          distance: "50" // Use string format for consistency
        }));
      }
    }
  }, [views, filters.distance]);
  
  // Check if filters have changed from initial state
  useEffect(() => {
    const defaultStatus = initialFilterState.status || [];
    const currentStatus = filters.status || [];
    
    const isChanged = Boolean(
      (filters.search !== null && filters.search !== '') ||
      (currentStatus.length !== defaultStatus.length || 
       !currentStatus.every(status => defaultStatus.includes(status))) ||
      filters.sourceStates.length > 0 ||
      filters.sourceCountries.length > 0 ||
      filters.sourceContinent.length > 0 ||
      filters.destinationStates.length > 0 ||
      filters.destinationCountries.length > 0 ||
      filters.destinationContinent.length > 0 ||
      filters.category.length > 0 ||
      filters.seasons.length > 0 ||
      (filters.distance !== initialFilterState.distance) ||
      (filters.location && filters.location !== '')
    );

    setIsFilterChanged(isChanged);
  }, [
    filters.search,
    filters.status,
    filters.sourceStates,
    filters.sourceCountries,
    filters.sourceContinent,
    filters.destinationStates,
    filters.destinationCountries,
    filters.destinationContinent,
    filters.category,
    filters.seasons,
    filters.distance,
    filters.location
  ]);
  
  // Handle search input change with debounce
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim() === '' ? null : e.target.value;
    
    // Update local state immediately
    setFilters(prevFilters => ({ ...prevFilters, search: value }));
    
    // Apply search filter with debounce
    setIsApplyingFilter(true);
    
    // Using setTimeout with cleanup
    const timer = setTimeout(() => {
      // Create a new object to avoid state closure issues
      onFilter({ ...filters, search: value });
      setIsApplyingFilter(false);
    }, 500);
    
    return () => clearTimeout(timer);
  };
  
  // Clear search
  const clearSearch = () => {
    // Update local state immediately
    setFilters(prevFilters => ({ ...prevFilters, search: null }));
    
    // Apply filter change
    setIsApplyingFilter(true);
    
    const timer = setTimeout(() => {
      // Create a new object to avoid state closure issues
      onFilter({ ...filters, search: null });
      setIsApplyingFilter(false);
    }, 300);
    
    return () => clearTimeout(timer);
  };
  
  // Status filter click
  const handleStatusFilter = (status: string) => {
    const isSelected = filters.status?.includes(status) || false;
    let newStatus: string[];
    
    if (isSelected) {
      newStatus = filters.status?.filter(s => s !== status) || [];
      if (newStatus.length === 0) newStatus = ['confirmed']; // Default to confirmed if nothing selected
    } else {
      newStatus = [...(filters.status || [])];
      if (!newStatus.includes(status)) {
        newStatus.push(status);
      }
    }
    
    // Create new filter object
    const newFilters = {...filters, status: newStatus};
    
    // First update local state
    setFilters(newFilters);
    
    // Then apply filter
    setIsApplyingFilter(true);
    const timer = setTimeout(() => {
      onFilter(newFilters); // Use the newFilters object directly
      setIsApplyingFilter(false);
    }, 300);
    
    return () => clearTimeout(timer);
  };
  
  // Apply filter changes - this should ONLY update state, not apply filters
  const handleFilterChange = (newFilters: FilterState) => {
    setFilters(newFilters);
    // No automatic filter application - we only do this explicitly
  };
  
  // Function to explicitly apply current filters
  const applyCurrentFilters = () => {
    // Capture current filter state
    const currentFilters = {...filters};
    
    setIsApplyingFilter(true);
    const timer = setTimeout(() => {
      onFilter(currentFilters);
      setIsApplyingFilter(false);
    }, 300);
    
    return () => clearTimeout(timer);
  };
  
  // Handle checkbox change in advanced filters
  const handleCheckboxChange = (category: keyof FilterState, value: string) => {
    const currentValues = filters[category] as string[];
    const newValues = currentValues.includes(value)
      ? currentValues.filter(v => v !== value)
      : [...currentValues, value];
    
    handleFilterChange({ ...filters, [category]: newValues });
  };
  
  // Handle distance change
  const handleDistanceChange = (value: string) => {
    handleFilterChange({ ...filters, distance: value });
  };
  
  // Handle sort change
  const handleSortChange = (field: string) => {
    if (filters.sortBy === field) {
      // Toggle direction if same field
      handleFilterChange({
        ...filters,
        sortDirection: filters.sortDirection === 'asc' ? 'desc' : 'asc'
      });
    } else {
      // Set new field with default direction
      handleFilterChange({
        ...filters,
        sortBy: field,
        sortDirection: 'desc'
      });
    }
  };
  
  // Clear all filters
  const clearFilters = () => {
    handleFilterChange(initialFilterState);
    setActiveFilterId(null);
  };
  
  // Save current filter
  const saveCurrentFilter = () => {
    if (!filterName.trim()) return;
    
    const newFilter: SavedFilter = {
      id: Date.now().toString(),
      name: filterName,
      filter: { ...filters },
      createdAt: new Date()
    };
    
    setSavedFilters(prev => [newFilter, ...prev]);
    setFilterName('');
    setIsSaveModalOpen(false);
    setActiveFilterId(newFilter.id);
  };
  
  // Load saved filter
  const loadSavedFilter = (filterId: string) => {
    const filterToLoad = savedFilters.find(f => f.id === filterId);
    if (filterToLoad) {
      handleFilterChange(filterToLoad.filter);
      setActiveFilterId(filterId);
    }
  };
  
  // Delete saved filter
  const deleteSavedFilter = (filterId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setSavedFilters(prev => prev.filter(f => f.id !== filterId));
    if (activeFilterId === filterId) {
      setActiveFilterId(null);
    }
  };
  
  // Select all items in a category
  const selectAll = (category: keyof FilterState, items: string[]) => {
    handleFilterChange({
      ...filters,
      [category]: [...items]
    });
  };
  
  // Clear all items in a category
  const clearAll = (category: keyof FilterState) => {
    handleFilterChange({
      ...filters,
      [category]: []
    });
  };
  
  // Count selected items in a category
  const countSelected = (category: keyof FilterState) => {
    return (filters[category] as string[]).length;
  };

  return (
    <div className="mb-6 relative" ref={filterContainerRef}>
      <div className="flex flex-wrap justify-between items-center gap-2">
        {/* Search input */}
        <div className="relative flex-grow max-w-md">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
          <input
            type="text"
            placeholder="Search views..."
            value={filters.search || ""}
            onChange={handleSearchChange}
            className="pl-10 pr-10 py-2 w-full border rounded-md bg-background focus:outline-none focus:ring-2 focus:ring-primary/30 focus:border-primary transition-all"
            disabled={isLoading || isApplyingFilter}
            title="Search by name, location, or keywords"
          />
          {filters.search && (
            <button
              type="button" 
              onClick={clearSearch}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-muted-foreground hover:text-foreground"
            >
              <X className="h-4 w-4" />
            </button>
          )}
          {isApplyingFilter && (
            <Loader2 className="absolute right-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground animate-spin" />
          )}
        </div>
          
        {/* Quick status filter chips */}
        <div className="flex flex-wrap gap-1.5">
          {availableStatuses.map(status => {
            const isSelected = filters.status?.includes(status) || false;
            
            return (
              <button
                key={status}
                type="button"
                onClick={() => handleStatusFilter(status)}
                className={`px-3 py-1.5 text-xs rounded-md transition-all duration-200 flex items-center gap-1.5 ${
                  isSelected
                    ? 'border-primary bg-primary/10 text-primary border shadow-sm scale-105' 
                    : 'border-muted/70 bg-background hover:bg-muted/30 text-muted-foreground hover:text-foreground border hover:scale-105'
                }`}
                title={`Filter by ${getStatusLabel(status)} status`}
              >
                <span className={`inline-block w-2.5 h-2.5 rounded-full ${getStatusColor(status)}`} />
                <span className={isSelected ? 'font-medium' : ''}>{getStatusLabel(status)}</span>
              </button>
            );
          })}
        </div>
        
        <div className="flex items-center gap-2 ml-auto">
          <button
            type="button"
            onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
            className={cn(
              "flex items-center gap-1.5 px-3 py-1.5 border rounded-md bg-background hover:bg-muted/30 transition-all duration-200",
              isAdvancedOpen ? "bg-primary/10 text-primary border-primary/30 shadow-sm" : "border-input"
            )}
            disabled={isLoading || isApplyingFilter}
            title="Toggle advanced filters panel"
          >
            <Filter className={cn("h-4 w-4", isAdvancedOpen && "text-primary")} />
            <span className="text-sm">Filters</span>
            <div className={cn(
              "transition-transform duration-200",
              isAdvancedOpen ? "rotate-180" : "rotate-0"
            )}>
              <ChevronDown className="h-4 w-4" />
            </div>
          </button>
           
          {isFilterChanged && (
            <button
              type="button"
              onClick={clearFilters}
              className="text-sm text-primary hover:text-primary/80 transition-colors"
              title="Reset all filters to default values"
            >
              Clear All
            </button>
          )}
        </div>
      </div>

      {/* Advanced filter panel - now positioned absolutely */}
      {isAdvancedOpen && (
        <div 
          ref={filterPanelRef}
          className="absolute left-0 right-0 top-full z-50 mt-2 rounded-md border p-4 bg-background/95 backdrop-blur-md shadow-lg transition-all duration-300 animate-in fade-in slide-in-from-top-2"
          style={{
            boxShadow: '0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)',
            maxHeight: '80vh',
            overflowY: 'auto'
          }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Status filter section */}
            <div className="bg-card p-3 rounded-md border border-border/50">
              <h3 className="font-medium mb-2 text-sm flex items-center">
                <span className="h-3 w-3 rounded-full bg-gradient-to-r from-green-500 to-blue-500 mr-2" />
                Status
              </h3>
              <div className="space-y-1.5">
                {availableStatuses.map(status => (
                  <div key={status} className="flex items-center">
                    <input
                      type="checkbox"
                      id={`status-${status}`}
                      checked={filters.status?.includes(status) || false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        const currentStatus = filters.status || [];
                        
                        let newStatus = [...currentStatus];
                        
                        if (isChecked && !currentStatus.includes(status)) {
                          newStatus.push(status);
                        } else if (!isChecked && currentStatus.includes(status)) {
                          newStatus = newStatus.filter(s => s !== status);
                          // Ensure at least one status is selected
                          if (newStatus.length === 0) newStatus = ['confirmed'];
                        }
                        
                        // Create a new filter object
                        const newFilters = {...filters, status: newStatus};
                        
                        // Update state only - will be applied when user clicks Apply button
                        setFilters(newFilters);
                      }}
                      className="mr-2 h-4 w-4 rounded-sm accent-primary transition-all"
                    />
                    <label htmlFor={`status-${status}`} className="text-sm flex items-center gap-1.5 cursor-pointer">
                      <span className={`inline-block w-2 h-2 rounded-full ${getStatusColor(status)}`} />
                      {getStatusLabel(status)}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            {/* Distance range filter */}
            <div className="bg-card p-3 rounded-md border border-border/50">
              <h3 className="font-medium mb-2 text-sm flex items-center">
                <span className="h-3 w-3 rounded-full bg-gradient-to-r from-cyan-500 to-blue-500 mr-2" />
                Distance (miles)
              </h3>
              <div className="px-1 pt-2 pb-6">
                {/* Range input */}
                <input 
                  type="range"
                  min="0"
                  max="100"
                  step="5"
                  value={typeof filters.distance === 'string' ? filters.distance : "50"}
                  onChange={(e) => {
                    handleFilterChange({...filters, distance: e.target.value});
                  }}
                  className="w-full accent-primary"
                  disabled={isLoading || isApplyingFilter}
                />
                <div className="flex justify-between text-xs text-muted-foreground mt-1">
                  <span>0</span>
                  <span>50</span>
                  <span>100</span>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex gap-1">
                  {[10, 25, 50].map(distance => (
                    <button
                      key={distance}
                      type="button"
                      onClick={() => handleFilterChange({...filters, distance: distance.toString()})}
                      className={cn(
                        "px-2 py-1 text-xs rounded border transition-all duration-200",
                        filters.distance === distance.toString() 
                          ? "bg-primary/10 border-primary/30 text-primary shadow-sm" 
                          : "border-input hover:border-primary/30 hover:bg-muted/30"
                      )}
                    >
                      {distance}
                    </button>
                  ))}
                </div>
                <div>
                  <input
                    type="number"
                    value={typeof filters.distance === 'string' ? filters.distance : ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === "" || Number.parseInt(value, 10) >= 0) {
                        handleFilterChange({...filters, distance: value});
                      }
                    }}
                    className="w-16 px-2 py-1 text-sm border rounded text-right focus:ring-1 focus:ring-primary/30 focus:border-primary/70 transition-all bg-background"
                    min="0"
                    max="100"
                    disabled={isLoading || isApplyingFilter}
                  />
                </div>
              </div>
            </div>

            {/* Location filter */}
            <div className="bg-card p-3 rounded-md border border-border/50">
              <h3 className="font-medium mb-2 text-sm flex items-center">
                <span className="h-3 w-3 rounded-full bg-gradient-to-r from-orange-500 to-red-500 mr-2" />
                Location
              </h3>
              <div>
                <input
                  type="text"
                  placeholder="City or ZIP code"
                  value={filters.location || ""}
                  onChange={(e) => handleFilterChange({...filters, location: e.target.value})}
                  className="w-full px-3 py-2 text-sm border rounded focus:ring-1 focus:ring-primary/30 focus:border-primary/70 transition-all bg-background"
                  disabled={isLoading || isApplyingFilter}
                />
              </div>
            </div>
          </div>
          
          <div className="mt-6 pt-4 border-t grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Source location */}
            <div className="bg-card p-3 rounded-md border border-border/50">
              <div className="flex items-center justify-between mb-3">
                <h3 className="text-sm font-medium flex items-center">
                  <span className="h-3 w-3 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 mr-2" />
                  Source Location
                </h3>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={sourceSearchTerm}
                    onChange={(e) => setSourceSearchTerm(e.target.value)}
                    className="text-xs px-2 py-1 border rounded w-36 focus:ring-1 focus:ring-primary/30 focus:border-primary/70 transition-all bg-background"
                  />
                </div>
              </div>
              
              <div className="grid grid-cols-1 gap-4">
                {/* Source Continents */}
                <div>
                  <div className="flex items-center justify-between">
                    <h4 className="text-xs font-medium text-muted-foreground">Continents</h4>
                    <div className="flex items-center gap-1 text-xs">
                      <button 
                        type="button"
                        onClick={() => selectAll('sourceContinent', filteredSourceLocations.continents)}
                        className="text-primary hover:underline"
                      >
                        All
                      </button>
                      <span className="text-muted-foreground px-1">|</span>
                      <button 
                        type="button"
                        onClick={() => clearAll('sourceContinent')}
                        className="text-primary hover:underline"
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                  
                  <div className="mt-1 max-h-32 overflow-y-auto border rounded p-2 scrollbar-thin scrollbar-thumb-primary/20 scrollbar-track-transparent">
                    {filteredSourceLocations.continents.length === 0 ? (
                      <div className="text-xs text-muted-foreground text-center py-2">No results</div>
                    ) : (
                      <div className="space-y-1">
                        {filteredSourceLocations.continents.map(continent => (
                          <label key={continent} className="flex items-center text-xs cursor-pointer hover:bg-muted/30 p-1 rounded transition-colors">
                            <input
                              type="checkbox"
                              checked={filters.sourceContinent.includes(continent)}
                              onChange={() => handleCheckboxChange('sourceContinent', continent)}
                              className="mr-1.5 h-3 w-3 accent-primary"
                            />
                            {continent}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            
            {/* Destination location */}
            <div className="bg-card p-3 rounded-md border border-border/50">
              <div className="flex items-center justify-between mb-3">
                <h3 className="text-sm font-medium flex items-center">
                  <span className="h-3 w-3 rounded-full bg-gradient-to-r from-green-500 to-teal-500 mr-2" />
                  Destination Location
                </h3>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={destSearchTerm}
                    onChange={(e) => setDestSearchTerm(e.target.value)}
                    className="text-xs px-2 py-1 border rounded w-36 focus:ring-1 focus:ring-primary/30 focus:border-primary/70 transition-all bg-background"
                  />
                </div>
              </div>
              
              <div className="grid grid-cols-1 gap-4">
                {/* Destination Continents */}
                <div>
                  <div className="flex items-center justify-between">
                    <h4 className="text-xs font-medium text-muted-foreground">Continents</h4>
                    <div className="flex items-center gap-1 text-xs">
                      <button 
                        type="button"
                        onClick={() => selectAll('destinationContinent', filteredDestLocations.continents)}
                        className="text-primary hover:underline"
                      >
                        All
                      </button>
                      <span className="text-muted-foreground px-1">|</span>
                      <button 
                        type="button"
                        onClick={() => clearAll('destinationContinent')}
                        className="text-primary hover:underline"
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                  
                  <div className="mt-1 max-h-32 overflow-y-auto border rounded p-2 scrollbar-thin scrollbar-thumb-primary/20 scrollbar-track-transparent">
                    {filteredDestLocations.continents.length === 0 ? (
                      <div className="text-xs text-muted-foreground text-center py-2">No results</div>
                    ) : (
                      <div className="space-y-1">
                        {filteredDestLocations.continents.map(continent => (
                          <label key={continent} className="flex items-center text-xs cursor-pointer hover:bg-muted/30 p-1 rounded transition-colors">
                            <input
                              type="checkbox"
                              checked={filters.destinationContinent.includes(continent)}
                              onChange={() => handleCheckboxChange('destinationContinent', continent)}
                              className="mr-1.5 h-3 w-3 accent-primary"
                            />
                            {continent}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="flex justify-end mt-6 gap-2 border-t pt-4">
            <button
              type="button"
              onClick={() => {
                // Reset filters to initial state
                const resetFilters = {...initialFilterState};
                
                // Update UI state first
                setFilters(resetFilters);
                setActiveFilterId(null);
                
                // Then apply filters
                setIsApplyingFilter(true);
                const timer = setTimeout(() => {
                  onFilter(resetFilters);
                  setIsApplyingFilter(false);
                }, 300);
              }}
              className="px-4 py-2 text-sm text-muted-foreground hover:text-foreground transition-colors"
              disabled={isLoading || isApplyingFilter}
            >
              Reset
            </button>
            <button
              type="button"
              onClick={() => {
                // First close the panel
                setIsAdvancedOpen(false);
                
                // Capture current filter state
                const currentFilters = {...filters};
                
                // Then apply filters
                setIsApplyingFilter(true);
                const timer = setTimeout(() => {
                  onFilter(currentFilters);
                  setIsApplyingFilter(false);
                }, 300);
              }}
              className="px-4 py-2 text-sm bg-primary text-primary-foreground rounded hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary/30 flex items-center gap-2 shadow-sm transition-all"
              disabled={isLoading || isApplyingFilter}
            >
              {(isLoading || isApplyingFilter) && (
                <Loader2 className="h-4 w-4 animate-spin" />
              )}
              Apply & Close
            </button>
          </div>
          
          {/* Mobile close button */}
          {isMobile && (
            <button 
              type="button"
              onClick={() => setIsAdvancedOpen(false)}
              className="absolute top-2 right-2 p-1 rounded-full bg-muted/50 hover:bg-muted text-muted-foreground hover:text-foreground transition-colors"
              aria-label="Close filter panel"
            >
              <X className="h-4 w-4" />
            </button>
          )}
        </div>
      )}
    </div>
  );
}
