import { useState } from 'react';
import { Mail, Send, User, MessageSquare, Facebook, Instagram, Twitter, Loader2 } from 'lucide-react';
import { useToast } from '../../components/ui/toast';
import { handleApiRequest, type ApiResponse } from '../../lib/apiHandler';
import { ContactPageSEO } from './SEO';

interface ContactFormData {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export default function ContactPage() {
  const { toast } = useToast();
  const [formData, setFormData] = useState<ContactFormData>({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      // Convert formData to a Record<string, unknown> to satisfy TypeScript
      const formDataAsRecord: Record<string, unknown> = {
        name: formData.name,
        email: formData.email,
        subject: formData.subject,
        message: formData.message
      };
      
      // Send email using our API handler
      const result = await handleApiRequest('/api/contact', 'POST', formDataAsRecord);
      
      if (!result.success) {
        console.error('Form submission error:', result.error);
        throw new Error(result.error);
      }
      
      console.log('Contact form submitted successfully:', result.data);
      
      toast({
        title: "Message sent!",
        description: "Thank you for your message. We'll get back to you soon."
      });
      
      // Reset form
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error('Error sending email:', error);
      
      let errorMessage = "There was a problem sending your message. Please try again.";
      if (error instanceof Error) {
        errorMessage = `Error: ${error.message}`;
      }
      
      toast({
        title: "Message failed",
        description: errorMessage
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <ContactPageSEO />
      <div className="container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-3xl font-bold mb-2 gradient-text">Contact Us</h1>
        <p className="text-foreground mb-8">Have questions or feedback? Get in touch with the TheViewShed team.</p>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="md:col-span-2">
            <form onSubmit={handleSubmit} className="space-y-6 bg-card border border-border rounded-lg p-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium mb-1 flex items-center">
                  <User className="h-4 w-4 mr-2 text-primary" />
                  Your Name <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="John Doe"
                />
              </div>
              
              <div>
                <label htmlFor="email" className="block text-sm font-medium mb-1 flex items-center">
                  <Mail className="h-4 w-4 mr-2 text-primary" />
                  Email Address <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="your.email@example.com"
                />
              </div>
              
              <div>
                <label htmlFor="subject" className="block text-sm font-medium mb-1 flex items-center">
                  <MessageSquare className="h-4 w-4 mr-2 text-primary" />
                  Subject <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="Question about a view"
                />
              </div>
              
              <div>
                <label htmlFor="message" className="block text-sm font-medium mb-1 flex items-center">
                  <MessageSquare className="h-4 w-4 mr-2 text-primary" />
                  Message <span className="text-red-500 ml-1">*</span>
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows={6}
                  className="w-full px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="Your message here..."
                />
              </div>
              
              
              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-6 py-3 bg-primary text-primary-foreground rounded-md hover:bg-primary/90 transition-colors flex items-center disabled:opacity-70"
                >
                  {isSubmitting ? (
                    <>
                      <Loader2 className="animate-spin mr-2 h-4 w-4" />
                      Sending...
                    </>
                  ) : (
                    <>
                      <Send className="h-4 w-4 mr-2" />
                      Send Message
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
          
          <div className="bg-card border border-border rounded-lg p-6 h-fit">
            <h2 className="text-xl font-semibold mb-4 text-primary">Contact Information</h2>
            
            <div className="space-y-4">
              <div>
                <h3 className="text-sm font-medium text-primary mb-1">Email</h3>
                <p className="text-foreground">contact@theviewshed.com</p>
              </div>
              
              <div>
                <h3 className="text-sm font-medium text-primary mb-1">Social Media</h3>
                <div className="flex space-x-3 mt-2">
                  <a href="https://facebook.com" aria-label="Facebook" className="text-primary hover:text-primary/80 transition-colors">
                    <Facebook size={20} />
                  </a>
                  <a href="https://instagram.com" aria-label="Instagram" className="text-primary hover:text-primary/80 transition-colors">
                    <Instagram size={20} />
                  </a>
                </div>
              </div>
              
              <div>
                <h3 className="text-sm font-medium text-primary mb-1">Response Time</h3>
                <p className="text-foreground text-sm">We typically respond within 1-2 business days.</p>
              </div>
            </div>
            
            <div className="mt-6 pt-6 border-t border-border">
              <h3 className="text-sm font-medium text-primary mb-3">Frequently Asked</h3>
              <ul className="space-y-2 text-sm">
                <li>
                  <a href="/faq" className="text-foreground hover:text-primary transition-colors">
                    What is TheViewShed?
                  </a>
                </li>
                <li>
                  <a href="/faq" className="text-foreground hover:text-primary transition-colors">
                    How do I submit a view?
                  </a>
                </li>
                <li>
                  <a href="/faq" className="text-foreground hover:text-primary transition-colors">
                    What do the statuses mean?
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
} 