import type { View } from '../types';

const API_URL = import.meta.env.VITE_PUBLIC_API_URL;

interface ApiView {
  id: number;
  record_id: number;
  source: string;
  state: string;
  country: string;
  continent: string;
  destination: string;
  dest_state: string;
  dest_country: string;
  dest_continent: string;
  distance_km: string;
  distance_m: string;
  type: string;
  status: string;
  resource_1: string;
  resource_2: string;
  resource_3: string;
  resource_4: string;
  createdAt: string;
  listing_status: string;
}

interface SubmitFormData {
  source: string;
  state: string;
  country: string;
  destination: string;
  dest_state: string;
  dest_country: string;
  distance_km: string;
  distance_m: string;
  status: string;
  type: string;
  source_lat?: string;
  source_long?: string;
  dest_lat?: string;
  dest_long?: string;
  description?: string;
  email?: string;
  submitterName?: string;
  creditInfo?: string;
  creditName?: string;
  resource_2?: string | null;
}

// Transform API data to match our app's View type
const transformApiData = (apiView: ApiView): View => {
  // Use resource_2 as image if available and valid, otherwise use a placeholder
  const placeholderImage = "https://images.unsplash.com/photo-1519681393784-d120267933ba?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3";
  
  // Check if resource_2 exists and is a valid URL
  let image = placeholderImage;
  if (apiView.resource_2 && isValidImageUrl(apiView.resource_2)) {
    image = apiView.resource_2;
  }
  
  // Normalize the status value to handle different format variations from API
  const normalizeApiStatus = (status: string): "confirmed" | "likely" | "ifclear" | "plausible" | "dubious" | "unlikely" => {
    const lowerStatus = status.toLowerCase();
    
    // Handle all variations of "if clear"
    if (lowerStatus === 'if-clear' || lowerStatus === 'if clear' || lowerStatus === 'ifclear') {
      return 'ifclear';
    }
    
    // Handle other statuses
    if (lowerStatus === 'confirmed') return 'confirmed';
    if (lowerStatus === 'likely') return 'likely';
    if (lowerStatus === 'plausible') return 'plausible';
    if (lowerStatus === 'dubious') return 'dubious';
    if (lowerStatus === 'unlikely') return 'unlikely';
    
    // Default fallback
    return 'plausible';
  };
  
  // Map status values to ensure consistent format, but preserve all 6 categories
  const statusMap: Record<string, "confirmed" | "likely" | "ifclear" | "plausible" | "dubious" | "unlikely"> = {
    'confirmed': 'confirmed',
    'likely': 'likely',
    'if-clear': 'ifclear',  // normalize the format (remove dash)
    'if clear': 'ifclear',  // without dash
    'ifclear': 'ifclear',   // handle both formats
    'plausible': 'plausible',
    'dubious': 'dubious',
    'unlikely': 'unlikely'
  };

  
  // Generate a color based on the status
  const colorMap: Record<string, string> = {
    'confirmed': 'bg-green-500',
    'likely': 'bg-blue-500',
    'ifclear': 'bg-cyan-500',
    'plausible': 'bg-yellow-500',
    'dubious': 'bg-orange-500',
    'unlikely': 'bg-red-500'
  };

  // Get normalized status
  const normalizedStatus = normalizeApiStatus(apiView.status);

  return {
    id: apiView.record_id.toString(),
    distance: `${apiView.distance_km} km / ${apiView.distance_m} mi`,
    source: apiView.source,
    state: apiView.state,
    country: apiView.country,
    continent: apiView.continent,
    destination: apiView.destination,
    destinationState: apiView.dest_state,
    destinationCountry: apiView.dest_country,
    destinationContinent: apiView.dest_continent,
    status: normalizedStatus,
    image,
    color: colorMap[normalizedStatus],
    resource_3: apiView.resource_3,
    resource_4: apiView.resource_4
  };
};

// Helper function to check if a URL is likely a valid image
function isValidImageUrl(url: string): boolean {
  if (!url) return false;
  
  // Check if the URL has a valid image extension
  const validExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.svg'];
  const hasValidExtension = validExtensions.some(ext => url.toLowerCase().endsWith(ext));
  
  // Check if it's a URL from common image hosting services
  const isFromImageHost = 
    url.includes('unsplash.com') || 
    url.includes('imgur.com') || 
    url.includes('cloudinary.com') || 
    url.includes('images.') ||
    url.includes('.jpg?') ||
    url.includes('.png?') ||
    url.includes('.jpeg?') ||
    url.includes('.webp?');
  
  return hasValidExtension || isFromImageHost;
}

export const fetchViews = async (): Promise<View[]> => {
  try {
    const response = await fetch(API_URL);
    
    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }
    
    const data: ApiView[] = await response.json();
    
    // Log status values to debug
    console.log('API Status Values:', data.map(view => view.status));
    
    return data.map(transformApiData);
  } catch (error) {
    console.error('Error fetching views:', error);
    return [];
  }
};

export const submitView = async (formData: SubmitFormData): Promise<boolean> => {
  try {
    // This is a placeholder for future implementation
    // In a real app, you would send the form data to your backend
    console.log('Submitting view to API:', formData);
    
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    return true;
  } catch (error) {
    console.error('Error submitting view:', error);
    return false;
  }
}; 