import React from 'react';
import { Link } from 'react-router-dom';

export default function TermsAndConditionsPage() {
  return (
    <div className="max-w-4xl mx-auto py-12 px-4">
      <h1 className="text-3xl font-bold mb-8 gradient-text">Terms and Conditions</h1>
      
      <div className="prose prose-lg dark:prose-invert max-w-none">
        <p className="text-muted-foreground mb-8">
          Last updated: {new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
        </p>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
          <p>
            Welcome to TheViewShed. These terms and conditions outline the rules and regulations for the use of our website.
          </p>
          <p className="mt-2">
            By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use
            TheViewShed if you do not accept all of the terms and conditions stated on this page.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Intellectual Property Rights</h2>
          <p>
            All content and materials available on TheViewShed are owned by their respective creators and contributors. 
            TheViewShed does not claim ownership rights over user-submitted content or other third-party materials. 
            Each piece of content remains the intellectual property of its original creator, who retains all rights 
            to their work unless explicitly stated otherwise.
          </p>
          <p className="mt-4">
            When using TheViewShed, please respect the intellectual property rights of others by:
          </p>
          <ul className="list-disc pl-6 mt-2 space-y-2">
            <li>Properly attributing content to its original creator when sharing or referencing it</li>
            <li>Only using content in accordance with the permissions granted by its owner</li>
            <li>Not claiming ownership of content that belongs to others</li>
            <li>Obtaining proper permission before using content for commercial purposes</li>
          </ul>
          <p className="mt-4">
            TheViewShed itself (including its logo, design elements, and functionality) is protected by copyright and 
            trademark laws. You may view and access the website for personal use, but any other use requires prior written permission.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">User Contributions</h2>
          <p>
            When you submit content to our website, you grant us a worldwide, non-exclusive, royalty-free license to use,
            reproduce, adapt, publish, translate, and distribute your content. This license allows us to make your content
            available to the rest of the world and to let others do the same.
          </p>
          <p className="mt-4">
            You are responsible for the content you submit. The content must not be illegal, deceptive, misleading,
            harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.
          </p>
          <p className="mt-4">
            We reserve the right to remove any content that violates these terms or that we find objectionable for any reason.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Accuracy of Information</h2>
          <p>
            While we strive to provide accurate information about views and locations, we cannot guarantee the accuracy,
            completeness, or reliability of any information on our website. The information is provided "as is" without
            warranty of any kind.
          </p>
          <p className="mt-4">
            Users should verify information independently before making decisions based on it, especially when planning
            trips or activities based on the views listed on our website.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Limitation of Liability</h2>
          <p>
            In no event shall TheViewShed, nor any of its officers, directors, and employees, be liable for anything
            arising out of or in any way connected with your use of this website, whether such liability is under contract,
            tort, or otherwise.
          </p>
          <p className="mt-4">
            TheViewShed, including its officers, directors, and employees, shall not be liable for any indirect,
            consequential, or special liability arising out of or in any way related to your use of this website.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Indemnification</h2>
          <p>
            You hereby indemnify to the fullest extent TheViewShed from and against any and all liabilities, costs,
            demands, causes of action, damages, and expenses (including reasonable attorney's fees) arising out of or
            in any way related to your breach of any of the provisions of these terms.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Severability</h2>
          <p>
            If any provision of these terms is found to be unenforceable or invalid under any applicable law, such
            unenforceability or invalidity shall not render these terms unenforceable or invalid as a whole, and such
            provisions shall be deleted without affecting the remaining provisions herein.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Variation of Terms</h2>
          <p>
            TheViewShed is permitted to revise these terms at any time as it sees fit, and by using this website, you
            are expected to review such terms on a regular basis to ensure you understand all terms and conditions
            governing the use of this website.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Governing Law & Jurisdiction</h2>
          <p>
            These terms will be governed by and construed in accordance with the laws of the jurisdiction in which
            TheViewShed operates, and you submit to the non-exclusive jurisdiction of the courts located in that
            jurisdiction for the resolution of any disputes.
          </p>
        </section>
        
        <section>
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p>
            If you have any questions about these Terms and Conditions, please contact us:
          </p>
          <ul className="list-disc pl-6 mt-2 space-y-2">
            <li>By visiting our <Link to="/contact" className="text-primary hover:underline">Contact page</Link></li>
          </ul>
        </section>
      </div>
    </div>
  );
} 