import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Dialog, DialogContent } from './ui/dialog';
import type { View } from '../types';
import { 
  Share2, Navigation, Ruler, X, MapPin, ChevronLeft, ChevronRight, 
  ZoomIn, ZoomOut, Move, RotateCcw, Maximize, Download,
  Info, Eye, EyeOff, ChevronUp, ChevronDown, MoveRight
} from 'lucide-react';
import { cn } from '../lib/utils';
import { motion, useMotionValue, useTransform, AnimatePresence } from 'framer-motion';

// Add resource_3 and resource_4 to the interface if not already in types.ts
interface ViewWithCredit extends View {
  resource_3?: string; // Credit Link
  resource_4?: string; // Credit Info
}

interface ViewModalProps {
  view: ViewWithCredit | null;
  open: boolean;
  onClose: () => void;
  onShare: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
}

export function ViewModal({ 
  view, 
  open, 
  onClose, 
  onShare, 
  onNext, 
  onPrevious
}: ViewModalProps) {
  if (!view) return null;

  // Create a ref for the dialog content
  const dialogRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  
  // State for zoom and pan functionality
  const [zoom, setZoom] = useState(1);
  const [isPanning, setIsPanning] = useState(false);
  const [originalImageDimensions, setOriginalImageDimensions] = useState({ width: 0, height: 0, ratio: 0 });
  const [isWideImage, setIsWideImage] = useState(false);
  const [showWidePanoramicView, setShowWidePanoramicView] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showDetailsInFullscreen, setShowDetailsInFullscreen] = useState(true);
  const [detailsPanelState, setDetailsPanelState] = useState<'expanded' | 'collapsed' | 'hidden'>('collapsed');
  const [showZoomIndicator, setShowZoomIndicator] = useState(false);
  const zoomIndicatorTimeout = useRef<number | null>(null);
  
  // Motion values for panning
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  
  // Panoramic carousel effect
  const panoramaX = useMotionValue(0);
  const panoramaOpacity = useTransform(
    panoramaX,
    [-100, 0, 100], 
    [0.3, 1, 0.3]
  );

  // Update fullscreen state
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
    
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  // Handle keyboard navigation
  useEffect(() => {
    if (!open) return;
    
    // Focus the dialog with a slight delay to ensure it's rendered
    const focusTimer = setTimeout(() => {
      if (dialogRef.current) {
        dialogRef.current.focus();
      }
    }, 100);
    
    const handleKeyDown = (e: KeyboardEvent) => {
      // Only handle arrow keys when the modal is open
      if (e.key === 'ArrowLeft' && onPrevious) {
        e.preventDefault(); // Prevent scrolling
        e.stopPropagation(); // Stop event from bubbling
        onPrevious();
      } else if (e.key === 'ArrowRight' && onNext) {
        e.preventDefault(); // Prevent scrolling
        e.stopPropagation(); // Stop event from bubbling
        onNext();
      } else if (e.key === '+' || e.key === '=') {
        e.preventDefault();
        setZoom(prev => Math.min(prev + 0.25, 3));
      } else if (e.key === '-') {
        e.preventDefault();
        setZoom(prev => Math.max(prev - 0.25, 1));
      } else if (e.key === '0') {
        e.preventDefault();
        resetView();
      } else if (e.key === 'f' || e.key === 'F') {
        e.preventDefault();
        handleFullscreen();
      } else if (e.key === 'o' || e.key === 'O') {
        e.preventDefault();
        toggleOverlay();
      } else if (e.key === 'p' || e.key === 'P' && isWideImage) {
        e.preventDefault();
        togglePanoramicView();
      } else if (e.key === 'd' || e.key === 'D') {
        e.preventDefault();
        downloadImage();
      } else if (e.key === 'Escape' && isFullscreen) {
        e.preventDefault();
        document.exitFullscreen().catch(err => {
          console.error(`Error exiting fullscreen: ${err.message}`);
        });
      }
    };

    // Add the event listener directly to the dialog element
    if (dialogRef.current) {
      dialogRef.current.addEventListener('keydown', handleKeyDown);
    }
    // Also add it to the window as a fallback, but with capture phase
    window.addEventListener('keydown', handleKeyDown, true);
    
    return () => {
      clearTimeout(focusTimer);
      if (dialogRef.current) {
        dialogRef.current.removeEventListener('keydown', handleKeyDown);
      }
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [open, onNext, onPrevious, isWideImage, isFullscreen]);

  // Reset view when modal is opened
  useEffect(() => {
    if (open) {
      resetView();
    }
  }, [open]);

  // Check image dimensions when loaded
  const handleImageLoad = () => {
    if (imageRef.current) {
      const { naturalWidth, naturalHeight } = imageRef.current;
      const ratio = naturalWidth / naturalHeight;
      
      setOriginalImageDimensions({
        width: naturalWidth,
        height: naturalHeight,
        ratio
      });
      
      // Consider an image "wide" if its ratio is greater than 2.5:1
      setIsWideImage(ratio > 2.5);
    }
  };

  // Reset zoom and pan
  const resetView = () => {
    setZoom(1);
    x.set(0);
    y.set(0);
    panoramaX.set(0);
    setShowWidePanoramicView(false);
  };

  // Toggle panoramic view for wide images
  const togglePanoramicView = () => {
    setShowWidePanoramicView(!showWidePanoramicView);
    resetView();
  };

  // Toggle UI overlay
  const toggleOverlay = () => setShowOverlay(!showOverlay);

  // Toggle details visibility in fullscreen
  const toggleDetailsInFullscreen = () => {
    if (isFullscreen) {
      setShowDetailsInFullscreen(!showDetailsInFullscreen);
    }
  };

  // Toggle panel position for expanded, collapsed, hidden states
  const togglePanelState = () => {
    if (detailsPanelState === 'expanded') {
      setDetailsPanelState('collapsed');
    } else if (detailsPanelState === 'collapsed') {
      setDetailsPanelState('expanded');
    } else {
      setDetailsPanelState('collapsed');
    }
  };

  // When entering fullscreen, set panel to collapsed state
  useEffect(() => {
    if (isFullscreen) {
      setDetailsPanelState('collapsed');
    }
  }, [isFullscreen]);

  // Add function to download image
  const downloadImage = useCallback(() => {
    if (!view) return;
    
    const a = document.createElement('a');
    a.href = view.image;
    a.download = `view-${view.source}-to-${view.destination}.jpg`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [view]);

  // Fullscreen implementation
  const handleFullscreen = () => {
    if (imageContainerRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen().catch(err => {
          console.error(`Error exiting fullscreen: ${err.message}`);
        });
      } else {
        imageContainerRef.current.requestFullscreen().catch(err => {
          console.error(`Error attempting to enable fullscreen: ${err.message}`);
        });
      }
    }
  };

  // Parse distance to get both km and miles
  const distanceParts = view.distance.match(/^([\d.]+)\s*(km|mi)/i);
  const distance = {
    value: distanceParts ? Number.parseFloat(distanceParts[1]) : 0,
    unit: distanceParts ? distanceParts[2].toLowerCase() : 'km'
  };

  // Calculate the other unit (if km, calculate mi and vice versa)
  const otherUnit = distance.unit === 'km' ? 'mi' : 'km';
  const conversionFactor = distance.unit === 'km' ? 0.621371 : 1.60934;
  const otherValue = (distance.value * conversionFactor).toFixed(2);

  // Status badge color
  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'confirmed': return 'bg-green-500';
      case 'likely': return 'bg-blue-500';
      case 'ifclear': return 'bg-cyan-500';
      case 'plausible': return 'bg-yellow-500';
      case 'dubious': return 'bg-orange-500';
      case 'unlikely': return 'bg-red-500';
      default: return 'bg-gray-500';
    }
  };

  // Handle mouse wheel zoom 
  const handleWheel = useCallback((e: WheelEvent) => {
    if (isFullscreen) {
      e.preventDefault();
      const delta = e.deltaY * -0.01;
      setZoom(prevZoom => {
        // Calculate new zoom level with constraints
        const newZoom = Math.max(1, Math.min(3, prevZoom + delta));
        return newZoom;
      });
      
      // Show zoom indicator
      setShowZoomIndicator(true);
      
      // Clear existing timeout if any
      if (zoomIndicatorTimeout.current) {
        clearTimeout(zoomIndicatorTimeout.current);
      }
      
      // Hide zoom indicator after 1.5 seconds
      zoomIndicatorTimeout.current = setTimeout(() => {
        setShowZoomIndicator(false);
      }, 1500);
    }
  }, [isFullscreen]);

  // Clean up the timeout on unmount
  useEffect(() => {
    return () => {
      if (zoomIndicatorTimeout.current) {
        clearTimeout(zoomIndicatorTimeout.current);
      }
    };
  }, []);

  // Add/remove wheel event listener when fullscreen state changes
  useEffect(() => {
    const imageContainer = imageContainerRef.current;
    
    if (imageContainer && isFullscreen) {
      imageContainer.addEventListener('wheel', handleWheel, { passive: false });
      
      return () => {
        imageContainer.removeEventListener('wheel', handleWheel);
      };
    }
  }, [isFullscreen, handleWheel]);

  return (
    <Dialog 
      open={open} 
      onOpenChange={(value) => !value && onClose()}
    >
      <DialogContent 
        className="sm:max-w-[85vw] max-h-[95vh] p-0 border-none bg-gray-950 overflow-auto shadow-xl focus:outline-none outline-none rounded-none ring-0 ring-offset-0 border-l-2 border-cyan-500/40"
        ref={dialogRef}
        tabIndex={0}
        onKeyDown={(e) => {
          // Handle all arrow key presses in the dialog
          if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            e.preventDefault();
            e.stopPropagation();
            
            if (e.key === 'ArrowLeft' && onPrevious) {
              onPrevious();
            } else if (e.key === 'ArrowRight' && onNext) {
              onNext();
            }
          }
        }}
        data-keyboard-trap="true"
        style={{ outline: 'none' }}
      >
        <div className={`flex flex-col ${isFullscreen ? 'h-screen' : 'max-h-[95vh]'}`}>
          <div 
            ref={imageContainerRef}
            className={`relative ${isFullscreen ? 'w-full h-full' : ''}`}
          >
            {/* The container for the image */}
            <div className={`relative overflow-hidden bg-black ${isFullscreen ? 'w-full h-screen' : 'h-[60vh]'}`}>
              {/* Animated tech border line effect */}
              <div className="absolute top-0 left-0 bottom-0 w-0.5 bg-gradient-to-b from-cyan-500/20 via-cyan-400/60 to-blue-500/20 animate-pulse" />
              
              {/* Image container with advanced controls */}
              <div 
                className="w-full h-full relative bg-[#050a14] overflow-hidden"
                title={isFullscreen ? "Use mouse wheel to zoom in/out" : ""}
              >
                {/* Panoramic view for ultra-wide images */}
                {showWidePanoramicView && isWideImage ? (
                  <motion.div 
                    className="w-full h-full flex items-center justify-center overflow-hidden"
                    style={{ 
                      x: panoramaX,
                      opacity: panoramaOpacity
                    }}
                  >
                    <img
                      src={view.image}
                      alt={`View from ${view.source} to ${view.destination}`}
                      className="h-full object-cover"
                      style={{ 
                        maxWidth: 'none', 
                        width: 'auto',
                        imageRendering: 'auto' 
                      }}
                      loading="eager"
                      onLoad={handleImageLoad}
                      ref={imageRef}
                    />
                  </motion.div>
                ) : (
                  /* Interactive zoom and pan for normal view */
                  <motion.div 
                    className="w-full h-full flex items-center justify-center overflow-hidden"
                    style={{ 
                      cursor: isPanning ? 'grabbing' : (zoom > 1 ? 'grab' : 'default') 
                    }}
                    onMouseDown={() => {
                      if (zoom > 1) setIsPanning(true);
                    }}
                    onMouseUp={() => setIsPanning(false)}
                    onMouseLeave={() => setIsPanning(false)}
                    onMouseMove={(e) => {
                      if (isPanning && zoom > 1) {
                        x.set(x.get() + e.movementX);
                        y.set(y.get() + e.movementY);
                      }
                    }}
                  >
                    <motion.img
                      src={view.image}
                      alt={`View from ${view.source} to ${view.destination}`}
                      className="w-full h-full object-contain"
                      style={{ 
                        scale: zoom,
                        x: x,
                        y: y,
                        imageRendering: 'auto'
                      }}
                      loading="eager"
                      onLoad={handleImageLoad}
                      ref={imageRef}
                      drag={zoom > 1}
                      dragConstraints={imageContainerRef}
                      dragElastic={0.1}
                      dragTransition={{ power: 0.1, timeConstant: 250 }}
                    />
                  </motion.div>
                )}
                
                {showOverlay && (
                  <>
                    {/* Refined hexagonal grid overlay for sci-fi effect */}
                    <div className="absolute inset-0 pointer-events-none bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjAgMmw3LjI1IDEyLjU2TDM0LjUgMjdIMjBsLTE0LjUtMTIuNDRMMjAgMnoiIGZpbGw9Im5vbmUiIHN0cm9rZT0icmdiYSgwLDIwMCwyNTUsMC4wNSkiIHN0cm9rZS13aWR0aD0iMSIvPjwvc3ZnPg==')] opacity-30" />
                    
                    {/* Glowing frame edge */}
                    <div className="absolute inset-0 pointer-events-none shadow-[inset_0_0_30px_rgba(0,180,255,0.1)]" />
                  </>
                )}
                
                {showOverlay && (
                  <>
                    {/* Only one close button - in top right */}
                    <button 
                      type="button" 
                      onClick={onClose}
                      className="absolute top-4 right-4 p-2 bg-gray-900/80 backdrop-blur-sm rounded-full text-white hover:bg-cyan-600/40 transition-colors z-50 border border-cyan-500/20 shadow-[0_0_8px_rgba(0,200,255,0.2)]"
                      aria-label="Close dialog"
                    >
                      <X className="h-5 w-5" />
                    </button>
                    
                    {/* Image controls toolbar */}
                    <div className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-gray-900/60 backdrop-blur-sm rounded-full border border-cyan-500/20 shadow-[0_0_12px_rgba(0,180,255,0.15)] px-2 py-1 flex items-center space-x-2 z-50">
                      {/* Zoom controls */}
                      <button
                        type="button"
                        onClick={() => setZoom(prev => Math.min(prev + 0.25, 3))}
                        className="p-2 rounded-full text-white hover:bg-cyan-600/40 transition-colors disabled:opacity-50"
                        disabled={zoom >= 3}
                        aria-label="Zoom in"
                      >
                        <ZoomIn className="h-5 w-5" />
                        <span className="sr-only">Zoom in (+)</span>
                      </button>
                      
                      <button
                        type="button"
                        onClick={() => setZoom(prev => Math.max(prev - 0.25, 1))}
                        className="p-2 rounded-full text-white hover:bg-cyan-600/40 transition-colors disabled:opacity-50"
                        disabled={zoom <= 1}
                        aria-label="Zoom out"
                      >
                        <ZoomOut className="h-5 w-5" />
                        <span className="sr-only">Zoom out (-)</span>
                      </button>
                      
                      <div className="w-px h-6 bg-cyan-500/20" />
                      
                      {/* Reset view button */}
                      <button
                        type="button"
                        onClick={resetView}
                        className="p-2 rounded-full text-white hover:bg-cyan-600/40 transition-colors flex items-center gap-1.5"
                        aria-label="Reset view"
                      >
                        <RotateCcw className="h-5 w-5" />
                        <span className="text-xs font-medium">Reset</span>
                        <span className="sr-only">view (0)</span>
                      </button>
                      
                      {/* Panoramic view toggle for wide images */}
                      {isWideImage && (
                        <>
                          <div className="w-px h-6 bg-cyan-500/20" />
                          
                          <button
                            type="button"
                            onClick={togglePanoramicView}
                            className={cn(
                              "p-2 rounded-full text-white transition-colors",
                              showWidePanoramicView ? "bg-cyan-600/40" : "hover:bg-cyan-600/20"
                            )}
                            aria-label={showWidePanoramicView ? "Exit panoramic view" : "Enter panoramic view"}
                          >
                            <Move className="h-5 w-5" />
                            <span className="sr-only">{showWidePanoramicView ? "Exit" : "Enter"} Panoramic View (P)</span>
                          </button>
                        </>
                      )}
                      
                      {/* Layout adjustment: keep this divider */}
                      <div className="w-px h-6 bg-cyan-500/20" />
                      
                      <button
                        type="button"
                        onClick={handleFullscreen}
                        className={cn(
                          "p-2 rounded-full text-white transition-colors",
                          isFullscreen ? "bg-cyan-600/40" : "hover:bg-cyan-600/20"
                        )}
                        aria-label="Toggle fullscreen"
                      >
                        <Maximize className="h-5 w-5" />
                        <span className="sr-only">Fullscreen (F)</span>
                      </button>
                      
                      <button
                        type="button"
                        onClick={downloadImage}
                        className="p-2 rounded-full text-white hover:bg-cyan-600/40 transition-colors"
                        aria-label="Download image"
                      >
                        <Download className="h-5 w-5" />
                        <span className="sr-only">Download (D)</span>
                      </button>
                      
                      <button
                        type="button"
                        onClick={toggleOverlay}
                        className="p-2 rounded-full text-white hover:bg-cyan-600/40 transition-colors"
                        aria-label="Toggle overlay"
                      >
                        <EyeOff className="h-5 w-5" />
                        <span className="sr-only">Hide UI (O)</span>
                      </button>
                      
                      {/* {zoom > 1 && !showWidePanoramicView && (
                        <div className="text-xs text-cyan-300/70 px-2">
                          Drag to pan
                        </div>
                      )} */}
                    </div>
                    
                    {/* Enhanced navigation buttons with larger size, padding, and shadow */}
                    {onPrevious && (
                      <button 
                        type="button" 
                        onClick={onPrevious}
                        className="absolute top-1/2 left-6 -translate-y-1/2 p-4 bg-gray-900/60 backdrop-blur-sm hover:bg-cyan-600/40 rounded-full text-white transition-colors z-50 shadow-lg border border-cyan-500/20 shadow-[0_0_12px_rgba(0,180,255,0.15)]"
                        aria-label="Previous view"
                      >
                        <ChevronLeft className="h-8 w-8" />
                      </button>
                    )}
                    
                    {onNext && (
                      <button 
                        type="button" 
                        onClick={onNext}
                        className="absolute top-1/2 right-6 -translate-y-1/2 p-4 bg-gray-900/60 backdrop-blur-sm hover:bg-cyan-600/40 rounded-full text-white transition-colors z-50 shadow-lg border border-cyan-500/20 shadow-[0_0_12px_rgba(0,180,255,0.15)]"
                        aria-label="Next view"
                      >
                        <ChevronRight className="h-8 w-8" />
                      </button>
                    )}
                    
                    {/* Show aspect ratio badge for wide images */}
                    {isWideImage && (
                      <div className="absolute top-4 left-4 px-3 py-1 bg-gray-900/70 backdrop-blur-sm rounded-full text-white border border-cyan-500/20 text-xs">
                        <span className="font-mono text-cyan-300">
                          {originalImageDimensions.ratio.toFixed(1)}:1
                        </span>
                        <span className="ml-1 text-cyan-400/70">ultra-wide</span>
                      </div>
                    )}
                    
                    {/* Move image dimensions to a more compact display */}
                    <div className="absolute bottom-4 left-4 px-3 py-1 bg-gray-900/70 backdrop-blur-sm rounded-full text-white border border-cyan-500/20 text-xs">
                      <span className="text-cyan-300/70">{originalImageDimensions.width} × {originalImageDimensions.height}</span>
                    </div>

                    {/* Only show zoom indicator when actively zooming */}
                    {showZoomIndicator && isFullscreen && (
                      <div className="absolute top-20 left-1/2 -translate-x-1/2 px-4 py-2 bg-gray-900/80 backdrop-blur-sm rounded-lg text-white z-50 border border-cyan-500/20 shadow-lg flex items-center gap-2">
                        <ZoomIn className="h-4 w-4 text-cyan-400" />
                        <div className="text-sm font-mono">
                          {Math.round(zoom * 100)}%
                        </div>
                      </div>
                    )}

                    {/* Keyboard shortcuts help */}
                    <div className="fixed bottom-4 right-4 z-50">
                      <button
                        type="button"
                        onClick={() => {
                          // Toggle keyboard shortcuts tooltip
                          const element = document.getElementById('keyboard-shortcuts');
                          if (element) {
                            element.classList.toggle('hidden');
                          }
                        }}
                        className="p-2 bg-gray-900/60 backdrop-blur-sm rounded-full text-white hover:bg-cyan-600/40 transition-colors border border-cyan-500/20 shadow-[0_0_8px_rgba(0,200,255,0.2)]"
                        aria-label="Keyboard shortcuts"
                      >
                        <span className="font-mono text-sm font-bold">Help</span>
                      </button>
                      
                      <div 
                        id="keyboard-shortcuts"
                        className="hidden absolute bottom-12 right-0 w-64 bg-gray-900/90 backdrop-blur-md rounded-lg text-white border border-cyan-500/20 shadow-[0_0_15px_rgba(0,180,255,0.15)] overflow-hidden"
                      >
                        <div className="px-4 py-2 bg-gradient-to-r from-cyan-900/40 to-blue-900/30 border-b border-cyan-500/20">
                          <h3 className="text-sm font-medium text-white">Keyboard Shortcuts</h3>
                        </div>
                        <div className="p-3 space-y-1.5 max-h-80 overflow-y-auto">
                          <div className="flex justify-between items-center">
                            <span className="text-xs text-cyan-300/80">Previous/Next</span>
                            <span className="text-xs text-white font-mono">←/→</span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-xs text-cyan-300/80">Zoom In/Out</span>
                            <span className="text-xs text-white font-mono">+/- or scroll</span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-xs text-cyan-300/80">Reset View</span>
                            <span className="text-xs text-white font-mono">0</span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-xs text-cyan-300/80">Fullscreen</span>
                            <span className="text-xs text-white font-mono">F</span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-xs text-cyan-300/80">Panoramic View</span>
                            <span className="text-xs text-white font-mono">P</span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-xs text-cyan-300/80">Hide UI</span>
                            <span className="text-xs text-white font-mono">O</span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-xs text-cyan-300/80">Download</span>
                            <span className="text-xs text-white font-mono">D</span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-xs text-cyan-300/80">Close</span>
                            <span className="text-xs text-white font-mono">ESC</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                
                {/* Show minimal controls even when overlay is hidden */}
                {!showOverlay && (
                  <button
                    type="button"
                    onClick={toggleOverlay}
                    className="absolute bottom-4 right-4 p-2 bg-gray-900/60 backdrop-blur-sm rounded-full text-white hover:bg-cyan-600/40 transition-colors z-50 border border-cyan-500/20 shadow-[0_0_8px_rgba(0,200,255,0.2)]"
                    aria-label="Show controls"
                  >
                    <Eye className="h-5 w-5" />
                  </button>
                )}
              </div>
            </div>

            {/* Comprehensive data display - now a collapsible panel */}
            {isFullscreen ? (
              <div 
                className={`fixed left-0 right-0 bottom-0 bg-gradient-to-b from-[#0a1222]/90 to-[#071018]/95 
                  transition-all duration-300 ease-in-out shadow-[0_-4px_12px_rgba(0,0,0,0.3)] 
                  border-t border-cyan-500/30 backdrop-blur-md z-50
                  ${detailsPanelState === 'expanded' ? 'max-h-[60vh]' : 
                    detailsPanelState === 'collapsed' ? 'max-h-[100px]' : 
                    'translate-y-full'}`}
              >
                {/* Pull handle / toggle */}
                <button 
                  className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 
                    w-12 h-5 bg-gradient-to-r from-cyan-900 to-blue-900 rounded-t-xl 
                    cursor-pointer flex items-center justify-center group hover:from-cyan-800 hover:to-blue-800"
                  onClick={togglePanelState}
                  type="button"
                  style={{zIndex: 7777}}
                  aria-label={detailsPanelState === 'expanded' ? "Collapse details panel" : "Expand details panel"}
                >
                  {detailsPanelState === 'expanded' ? (
                    <ChevronDown className="h-3 w-3 text-cyan-400/80 group-hover:text-cyan-400" />
                  ) : (
                    <ChevronUp className="h-3 w-3 text-cyan-400/80 group-hover:text-cyan-400" />
                  )}
                </button>

                {/* Collapsed view - only shows when in collapsed state */}
                {detailsPanelState === 'collapsed' && (
                  <div className="px-4 py-2 flex items-center justify-center h-[100px] bg-black/50 backdrop-blur-sm">
                    <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-6">
                      {/* Distance info */}
                      <div className="flex items-center space-x-3">
                        <Ruler className="h-5 w-5 text-cyan-400" />
                        <div className="font-mono text-xl font-bold text-white">
                          {distance.value.toLocaleString()} <span className="text-cyan-400">{distance.unit}</span>
                        </div>
                      </div>

                      {/* Divider */}
                      <div className="hidden sm:block w-px h-8 bg-white/20" />

                      {/* Location and status */}
                      <div className="flex flex-col sm:flex-row items-center sm:space-x-3">
                        <div className="text-white text-sm text-center sm:text-left">
                          <span className="font-medium">{view.source}</span>
                          <MoveRight className="inline-block mx-2 h-5 w-5 text-cyan-400" />
                          <span className="font-medium">{view.destination}</span>
                        </div>
                        <div className={`px-3 py-1 rounded-full text-xs font-semibold ${getStatusColor(view.status)} text-white mt-1 sm:mt-0`}>
                          {view.status}
                        </div>
                      </div>

                      {/* Credit info - if available */}
                      {(view.resource_3 || view.resource_4) && (
                        <>
                          {/* Divider */}
                          <div className="hidden sm:block w-px h-8 bg-white/20" />
                          <div className="text-white/70 text-xs">
                            {view.resource_4 && (
                              <span className="font-medium">{view.resource_4}</span>
                            )}
                            {view.resource_3 && (
                              <a 
                                href={view.resource_3} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="ml-1 text-cyan-400 hover:text-cyan-300 underline"
                              >
                                Link
                              </a>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}

                {/* Expanded view - full details */}
                <div className={`p-4 overflow-y-auto ${detailsPanelState === 'collapsed' ? 'hidden' : 'block'} max-h-[calc(60vh-20px)]`}>
                  {/* Full details content */}
                  <div className="flex flex-col space-y-4 max-w-7xl mx-auto">
                    {/* Distance - prominently displayed with Credits */}
                    <div className="flex items-center justify-between gap-4 mb-2">
                      <div className="flex items-center gap-4">
                        <div className="font-mono relative">
                          <div className="absolute -top-2.5 -left-2 text-xs text-cyan-500/50 font-semibold tracking-[0.2em]">DISTANCE</div>
                          <div className="text-3xl font-bold text-white tracking-tight mt-1.5">
                            {distance.value.toLocaleString()} <span className="text-cyan-400 font-semibold">{distance.unit}</span>
                            <span className="text-sm text-cyan-300/70 ml-2 font-normal">
                              ({otherValue} {otherUnit})
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* Credit information to the right */}
                      {(view.resource_3 || view.resource_4) && (
                        <div className="flex items-center gap-3 border-l border-gray-700/50 pl-4 py-2">
                          <div className="bg-gradient-to-br from-cyan-900/30 to-blue-900/20 p-2 rounded-full shadow-inner border border-cyan-500/30">
                            <Info className="h-5 w-5 text-cyan-400" />
                          </div>
                          <div className="flex flex-col">
                            <span className="text-xs text-cyan-300/70 uppercase tracking-wider font-medium">Credits</span>
                            <div className="flex items-center gap-2 mt-1">
                              {view.resource_4 && (
                                <span className="text-white text-sm">{view.resource_4}</span>
                              )}
                              {view.resource_3 && (
                                <a 
                                  href={view.resource_3} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                  className="text-cyan-400 hover:text-cyan-300 underline text-sm"
                                >
                                  View Source
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Source & Destination with connecting arrow */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 relative">
                      {/* Connecting line between source and destination */}
                      <div className="absolute top-12 left-[38%] right-[38%] h-0.5 bg-gradient-to-r from-cyan-400/20 via-cyan-500/40 to-blue-500/20 hidden md:block" />
                      
                      {/* Source details */}
                      <div className="space-y-3 relative">
                        <div className="flex items-center gap-2 mb-1">
                          <MapPin className="h-5 w-5 text-cyan-400" />
                          <h3 className="text-lg font-semibold text-white tracking-wide">Source</h3>
                          <div className="h-0.5 flex-grow bg-cyan-400/20 ml-2" />
                        </div>
                        
                        <div className="bg-gray-900/50 backdrop-blur-sm border-none rounded-lg p-4 space-y-3 shadow-md hover:shadow-lg transition-shadow border-l-2 border-cyan-400/30 relative overflow-hidden group">
                          {/* Tech corner accent */}
                          <div className="absolute top-0 right-0 w-12 h-12 pointer-events-none overflow-hidden">
                            <div className="w-16 h-16 border-2 border-cyan-500/20 rounded-full translate-x-8 -translate-y-8" />
                          </div>
                          
                          {/* HUD element */}
                          <div className="absolute -top-1 -left-1 w-3 h-3 border-t border-l border-cyan-400/30" />
                          
                          <p className="text-lg font-medium text-white group-hover:text-cyan-100 transition-colors">{view.source}</p>
                          
                          <div className="pt-2 grid grid-cols-2 gap-4">
                            <div>
                              <p className="text-xs text-cyan-300/70 uppercase tracking-wider font-medium flex items-center">
                                <span className="inline-block w-1.5 h-1.5 rounded-full bg-cyan-400/50 mr-1.5" />
                                State
                              </p>
                              <p className="text-sm text-white mt-1">{view.state}</p>
                            </div>
                            <div>
                              <p className="text-xs text-cyan-300/70 uppercase tracking-wider font-medium flex items-center">
                                <span className="inline-block w-1.5 h-1.5 rounded-full bg-cyan-400/50 mr-1.5" />
                                Country
                              </p>
                              <p className="text-sm text-white mt-1">{view.country}</p>
                            </div>
                            
                            {view.continent && (
                              <div className="col-span-2">
                                <p className="text-xs text-cyan-300/70 uppercase tracking-wider font-medium flex items-center">
                                  <span className="inline-block w-1.5 h-1.5 rounded-full bg-cyan-400/50 mr-1.5" />
                                  Continent
                                </p>
                                <p className="text-sm text-white mt-1">{view.continent}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* Destination details */}
                      <div className="space-y-3 relative">
                        <div className="flex items-center gap-2 mb-1">
                          <div className="h-0.5 flex-grow bg-blue-500/20 mr-2" />
                          <h3 className="text-lg font-semibold text-white tracking-wide">Destination</h3>
                          <Navigation className="h-5 w-5 text-blue-400" />
                        </div>
                        
                        <div className="bg-gray-900/50 backdrop-blur-sm border-none rounded-lg p-4 space-y-3 shadow-md hover:shadow-lg transition-shadow border-r-2 border-blue-500/30 relative overflow-hidden group">
                          {/* Tech corner accent */}
                          <div className="absolute -top-[1px] -left-[14px] w-12 h-12 pointer-events-none overflow-hidden">
                            <div className="w-16 h-16 border-2 border-blue-500/20 rounded-full -translate-x-8 -translate-y-8" />
                          </div>
                          
                          {/* HUD element */}
                          <div className="absolute -top-1 -right-1 w-3 h-3 border-t border-r border-blue-400/30" />
                          
                          <p className="text-lg font-medium text-white group-hover:text-blue-100 transition-colors">{view.destination}</p>
                          
                          <div className="pt-2 grid grid-cols-2 gap-4">
                            <div>
                              <p className="text-xs text-blue-300/70 uppercase tracking-wider font-medium flex items-center">
                                <span className="inline-block w-1.5 h-1.5 rounded-full bg-blue-400/50 mr-1.5" />
                                State
                              </p>
                              <p className="text-sm text-white mt-1">{view.destinationState}</p>
                            </div>
                            <div>
                              <p className="text-xs text-blue-300/70 uppercase tracking-wider font-medium flex items-center">
                                <span className="inline-block w-1.5 h-1.5 rounded-full bg-blue-400/50 mr-1.5" />
                                Country
                              </p>
                              <p className="text-sm text-white mt-1">{view.destinationCountry || view.country}</p>
                            </div>
                            
                            {view.destinationContinent && (
                              <div className="col-span-2">
                                <p className="text-xs text-blue-300/70 uppercase tracking-wider font-medium flex items-center">
                                  <span className="inline-block w-1.5 h-1.5 rounded-full bg-blue-400/50 mr-1.5" />
                                  Continent
                                </p>
                                <p className="text-sm text-white mt-1">{view.destinationContinent}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Status and Share button */}
                    <div className="flex justify-between items-center mt-3 pt-3 border-t border-gray-700/30">
                      <div className="flex items-center">
                        <div className={cn(
                          "flex items-center gap-2 px-4 py-2 rounded-full",
                          "bg-gray-900/70 backdrop-blur-sm shadow-sm border border-gray-700/50 relative overflow-hidden"
                        )}>
                          {/* Pulsing effect for status indicator */}
                          <span className={cn(
                            "w-3 h-3 rounded-full relative",
                            getStatusColor(view.status)
                          )}>
                            <span className={`absolute inset-0 rounded-full ${getStatusColor(view.status)} animate-ping opacity-50`} />
                          </span>
                          <span className="text-sm font-medium capitalize text-white tracking-wide">
                            {view.status}
                          </span>
                        </div>
                      </div>

                      {/* Share button */}
                      <button
                        type="button"
                        onClick={onShare}
                        className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-cyan-500/30 to-blue-500/30 backdrop-blur-sm text-white rounded-full hover:from-cyan-500/40 hover:to-blue-500/40 transition-all shadow-md hover:shadow-lg border border-cyan-500/20 group"
                      >
                        <Share2 className="h-4 w-4 group-hover:text-cyan-100" />
                        <span className="text-sm font-medium group-hover:text-cyan-100">Share</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              // Use original details panel for non-fullscreen mode
              <div className="w-full bg-gradient-to-b from-[#0a1222] to-[#071018] p-5 overflow-y-auto shadow-inner relative">
                {/* Original content */}
                <div className="flex flex-col space-y-4 max-w-7xl mx-auto">
                  {/* Distance - prominently displayed with Credits */}
                  <div className="flex items-center justify-between gap-4 mb-2">
                    <div className="flex items-center gap-4">
                      <div className="font-mono relative">
                        <div className="absolute -top-2.5 -left-2 text-xs text-cyan-500/50 font-semibold tracking-[0.2em]">DISTANCE</div>
                        <div className="text-3xl font-bold text-white tracking-tight mt-1.5">
                          {distance.value.toLocaleString()} <span className="text-cyan-400 font-semibold">{distance.unit}</span>
                          <span className="text-sm text-cyan-300/70 ml-2 font-normal">
                            ({otherValue} {otherUnit})
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* Credit information to the right */}
                    {(view.resource_3 || view.resource_4) && (
                      <div className="flex items-center gap-3 border-l border-gray-700/50 pl-4 py-2">
                        <div className="bg-gradient-to-br from-cyan-900/30 to-blue-900/20 p-2 rounded-full shadow-inner border border-cyan-500/30">
                          <Info className="h-5 w-5 text-cyan-400" />
                        </div>
                        <div className="flex flex-col">
                          <span className="text-xs text-cyan-300/70 uppercase tracking-wider font-medium">Credits</span>
                          <div className="flex items-center gap-2 mt-1">
                            {view.resource_4 && (
                              <span className="text-white text-sm">{view.resource_4}</span>
                            )}
                            {view.resource_3 && (
                              <a 
                                href={view.resource_3} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="text-cyan-400 hover:text-cyan-300 underline text-sm"
                              >
                                View Source
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Source & Destination with connecting arrow */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3 relative">
                    {/* Connecting line between source and destination */}
                    <div className="absolute top-12 left-[38%] right-[38%] h-0.5 bg-gradient-to-r from-cyan-400/20 via-cyan-500/40 to-blue-500/20 hidden md:block" />
                    
                    {/* Source details */}
                    <div className="space-y-3 relative">
                      <div className="flex items-center gap-2 mb-1">
                        <MapPin className="h-5 w-5 text-cyan-400" />
                        <h3 className="text-lg font-semibold text-white tracking-wide">Source</h3>
                        <div className="h-0.5 flex-grow bg-cyan-400/20 ml-2" />
                      </div>
                      
                      <div className="bg-gray-900/50 backdrop-blur-sm border-none rounded-lg p-4 space-y-3 shadow-md hover:shadow-lg transition-shadow border-l-2 border-cyan-400/30 relative overflow-hidden group">
                        {/* Tech corner accent */}
                        <div className="absolute top-0 right-0 w-12 h-12 pointer-events-none overflow-hidden">
                          <div className="w-16 h-16 border-2 border-cyan-500/20 rounded-full translate-x-8 -translate-y-8" />
                        </div>
                        
                        {/* HUD element */}
                        <div className="absolute -top-1 -left-1 w-3 h-3 border-t border-l border-cyan-400/30" />
                        
                        <p className="text-lg font-medium text-white group-hover:text-cyan-100 transition-colors">{view.source}</p>
                        
                        <div className="pt-2 grid grid-cols-2 gap-4">
                          <div>
                            <p className="text-xs text-cyan-300/70 uppercase tracking-wider font-medium flex items-center">
                              <span className="inline-block w-1.5 h-1.5 rounded-full bg-cyan-400/50 mr-1.5" />
                              State
                            </p>
                            <p className="text-sm text-white mt-1">{view.state}</p>
                          </div>
                          <div>
                            <p className="text-xs text-cyan-300/70 uppercase tracking-wider font-medium flex items-center">
                              <span className="inline-block w-1.5 h-1.5 rounded-full bg-cyan-400/50 mr-1.5" />
                              Country
                            </p>
                            <p className="text-sm text-white mt-1">{view.country}</p>
                          </div>
                          
                          {view.continent && (
                            <div className="col-span-2">
                              <p className="text-xs text-cyan-300/70 uppercase tracking-wider font-medium flex items-center">
                                <span className="inline-block w-1.5 h-1.5 rounded-full bg-cyan-400/50 mr-1.5" />
                                Continent
                              </p>
                              <p className="text-sm text-white mt-1">{view.continent}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Destination details */}
                    <div className="space-y-3 relative">
                      <div className="flex items-center gap-2 mb-1">
                        <div className="h-0.5 flex-grow bg-blue-500/20 mr-2" />
                        <h3 className="text-lg font-semibold text-white tracking-wide">Destination</h3>
                        <Navigation className="h-5 w-5 text-blue-400" />
                      </div>
                      
                      <div className="bg-gray-900/50 backdrop-blur-sm border-none rounded-lg p-4 space-y-3 shadow-md hover:shadow-lg transition-shadow border-r-2 border-blue-500/30 relative overflow-hidden group">
                        {/* Tech corner accent */}
                        <div className="absolute -top-[1px] -left-[14px] w-12 h-12 pointer-events-none overflow-hidden">
                          <div className="w-16 h-16 border-2 border-blue-500/20 rounded-full -translate-x-8 -translate-y-8" />
                        </div>
                        
                        {/* HUD element */}
                        <div className="absolute -top-1 -right-1 w-3 h-3 border-t border-r border-blue-400/30" />
                        
                        <p className="text-lg font-medium text-white group-hover:text-blue-100 transition-colors">{view.destination}</p>
                        
                        <div className="pt-2 grid grid-cols-2 gap-4">
                          <div>
                            <p className="text-xs text-blue-300/70 uppercase tracking-wider font-medium flex items-center">
                              <span className="inline-block w-1.5 h-1.5 rounded-full bg-blue-400/50 mr-1.5" />
                              State
                            </p>
                            <p className="text-sm text-white mt-1">{view.destinationState}</p>
                          </div>
                          <div>
                            <p className="text-xs text-blue-300/70 uppercase tracking-wider font-medium flex items-center">
                              <span className="inline-block w-1.5 h-1.5 rounded-full bg-blue-400/50 mr-1.5" />
                              Country
                            </p>
                            <p className="text-sm text-white mt-1">{view.destinationCountry || view.country}</p>
                          </div>
                          
                          {view.destinationContinent && (
                            <div className="col-span-2">
                              <p className="text-xs text-blue-300/70 uppercase tracking-wider font-medium flex items-center">
                                <span className="inline-block w-1.5 h-1.5 rounded-full bg-blue-400/50 mr-1.5" />
                                Continent
                              </p>
                              <p className="text-sm text-white mt-1">{view.destinationContinent}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Status and Share button */}
                  <div className="flex justify-between items-center mt-3 pt-3 border-t border-gray-700/30">
                    <div className="flex items-center">
                      <div className={cn(
                        "flex items-center gap-2 px-4 py-2 rounded-full",
                        "bg-gray-900/70 backdrop-blur-sm shadow-sm border border-gray-700/50 relative overflow-hidden"
                      )}>
                        {/* Pulsing effect for status indicator */}
                        <span className={cn(
                          "w-3 h-3 rounded-full relative",
                          getStatusColor(view.status)
                        )}>
                          <span className={`absolute inset-0 rounded-full ${getStatusColor(view.status)} animate-ping opacity-50`} />
                        </span>
                        <span className="text-sm font-medium capitalize text-white tracking-wide">
                          {view.status}
                        </span>
                      </div>
                    </div>

                    {/* Share button */}
                    <button
                      type="button"
                      onClick={onShare}
                      className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-cyan-500/30 to-blue-500/30 backdrop-blur-sm text-white rounded-full hover:from-cyan-500/40 hover:to-blue-500/40 transition-all shadow-md hover:shadow-lg border border-cyan-500/20 group"
                    >
                      <Share2 className="h-4 w-4 group-hover:text-cyan-100" />
                      <span className="text-sm font-medium group-hover:text-cyan-100">Share</span>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Toggle button for completely hiding/showing the panel in fullscreen */}
            {showOverlay && isFullscreen && (
              <button
                type="button"
                onClick={() => setDetailsPanelState(detailsPanelState === 'hidden' ? 'collapsed' : 'hidden')}
                className="absolute top-4 left-20 p-2 bg-gray-900/80 backdrop-blur-sm rounded-full text-white hover:bg-cyan-600/40 transition-colors z-50 border border-cyan-500/20 shadow-[0_0_8px_rgba(0,200,255,0.2)]"
                aria-label={detailsPanelState === 'hidden' ? "Show details" : "Hide details"}
              >
                <Info className="h-5 w-5" />
              </button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}