import React, { useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { cn } from '../lib/utils';
import { useKeyboardNavigation } from '../hooks/useKeyboardNavigation';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  perPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
  onPerPageChange: (perPage: number) => void;
  perPageOptions?: number[];
  showPerPageSelect?: boolean;
  persistInUrl?: boolean;
  enableKeyboardNavigation?: boolean;
  urlParamPrefix?: string;
  preserveParams?: string[];
}

export function Pagination({
  currentPage,
  totalPages,
  perPage,
  totalItems,
  onPageChange,
  onPerPageChange,
  perPageOptions = [24, 48, 96, 192],
  showPerPageSelect = true,
  persistInUrl = false,
  enableKeyboardNavigation = true,
  urlParamPrefix = '',
  preserveParams = ['view'],
}: PaginationProps) {
  const location = useLocation();
  const navigate = useNavigate();
  
  // Use keyboard navigation
  useKeyboardNavigation({
    currentPage,
    totalPages,
    onPageChange,
    enabled: enableKeyboardNavigation,
  });
  
  // Calculate pagination info
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = Math.min(startIndex + perPage, totalItems);
  
  // Calculate visible page numbers
  const maxVisiblePages = 5;
  
  // Create an array to hold the page numbers and ellipses
  const pageNumbers: (number | string)[] = [];
  
  // Always add the first page
  pageNumbers.push(1);
  
  // Calculate the middle range
  const startPage = Math.max(2, currentPage - Math.floor((maxVisiblePages - 2) / 2));
  const endPage = Math.min(totalPages - 1, startPage + maxVisiblePages - 3);
  
  // Add ellipsis after first page if needed
  if (startPage > 2) {
    pageNumbers.push('ellipsis-1');
  }
  
  // Add middle pages
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }
  
  // Add ellipsis before last page if needed
  if (endPage < totalPages - 1) {
    pageNumbers.push('ellipsis-2');
  }
  
  // Always add the last page if it's different from the first page
  if (totalPages > 1) {
    pageNumbers.push(totalPages);
  }
  
  // Update URL when page or perPage changes
  useEffect(() => {
    if (persistInUrl) {
      const params = new URLSearchParams(location.search);
      const pageParam = `${urlParamPrefix}page`;
      const perPageParam = `${urlParamPrefix}perPage`;
      
      // Only update if values have changed
      const currentPageParam = params.get(pageParam);
      const currentPerPageParam = params.get(perPageParam);
      
      let shouldUpdate = false;
      
      if (currentPageParam !== currentPage.toString()) {
        params.set(pageParam, currentPage.toString());
        shouldUpdate = true;
      }
      
      if (currentPerPageParam !== perPage.toString()) {
        params.set(perPageParam, perPage.toString());
        shouldUpdate = true;
      }
      
      if (shouldUpdate) {
        // Preserve specified parameters
        const newParams = new URLSearchParams();
        
        // Add pagination parameters
        newParams.set(pageParam, currentPage.toString());
        newParams.set(perPageParam, perPage.toString());
        
        // Preserve other parameters
        preserveParams.forEach(param => {
          const value = params.get(param);
          if (value) {
            newParams.set(param, value);
          }
        });
        
        navigate({ search: newParams.toString() }, { replace: true });
      }
    }
  }, [currentPage, perPage, persistInUrl, location.search, navigate, urlParamPrefix, preserveParams]);
  
  // Handle per page change
  const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPerPage = parseInt(e.target.value, 10);
    onPerPageChange(newPerPage);
  };
  
  // If there are no pages, don't render anything
  if (totalPages <= 0) return null;
  
  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between px-4 py-3 bg-card border border-border rounded-lg gap-4">
      <div className="flex flex-col sm:flex-row sm:items-center gap-4">
        <span className="text-sm text-muted-foreground">
          Showing <strong>{totalItems === 0 ? 0 : startIndex + 1}</strong>-<strong>{endIndex}</strong> of <strong>{totalItems}</strong>
        </span>
        
        {showPerPageSelect && (
          <div className="flex items-center gap-2">
            <label htmlFor="per-page" className="text-sm text-muted-foreground">
              Per page:
            </label>
            <select
              id="per-page"
              value={perPage}
              onChange={handlePerPageChange}
              className="px-2 py-1 text-sm border border-input rounded-md bg-background"
              aria-label="Items per page"
            >
              {perPageOptions.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      
      <nav aria-label="Pagination" className="flex items-center justify-center">
        <div className="flex items-center gap-2">
          {/* Previous page button */}
          <button
            type="button"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="relative p-2 rounded-md border border-border bg-background disabled:opacity-50 disabled:cursor-not-allowed overflow-hidden group"
          >
            <ChevronLeft className="h-4 w-4" />
            
            {/* Sci-fi hover effect */}
            <span className="absolute inset-0 bg-gradient-to-r from-primary/10 to-transparent translate-x-full group-hover:translate-x-0 transition-transform duration-300"></span>
          </button>
          
          {/* Page numbers */}
          {pageNumbers.map((page, index) => 
            typeof page === 'number' ? (
              <button
                key={`page-${page}`}
                type="button"
                onClick={() => onPageChange(page)}
                className={cn(
                  "relative z-0 px-3 py-1 rounded-md transition-all duration-200 overflow-hidden",
                  currentPage === page
                    ? "text-primary-foreground font-medium"
                    : "text-foreground hover:text-primary"
                )}
              >
                {/* Content */}
                <span className="relative z-10">{page}</span>
                
                {/* Background for current page with sci-fi styling */}
                {currentPage === page && (
                  <span className="absolute inset-0 bg-primary rounded-md z-0">
                    {/* Decorative dots */}
                    <span className="absolute top-1 right-1 w-0.5 h-0.5 rounded-full bg-primary-foreground/50" />
                    <span className="absolute bottom-1 left-1 w-0.5 h-0.5 rounded-full bg-primary-foreground/50" />
                  </span>
                )}
              </button>
            ) : (
              <span key={`${page}`} className="px-2 text-muted-foreground">...</span>
            )
          )}
          
          {/* Next page button */}
          <button
            type="button"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="relative p-2 rounded-md border border-border bg-background disabled:opacity-50 disabled:cursor-not-allowed overflow-hidden group"
          >
            <ChevronRight className="h-4 w-4" />
            
            {/* Sci-fi hover effect */}
            <span className="absolute inset-0 bg-gradient-to-l from-primary/10 to-transparent translate-x-full group-hover:translate-x-0 transition-transform duration-300"></span>
          </button>
        </div>
      </nav>
    </div>
  );
} 