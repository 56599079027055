import { useState, useRef } from 'react';
import { Mountain, Share2, MapPin, Navigation, Camera, Maximize2, ChevronRight, Bookmark, BookmarkPlus, Info } from 'lucide-react';
import { cn } from '../lib/utils';
import type { View } from '../types';
import { useToast } from './ui/toast';

// Extend the View type or handle the missing property
interface ExtendedView extends View {
  destinationCountry?: string;
}

interface ViewCardProps {
  view: ExtendedView;
  onClick: () => void;
  isCompact?: boolean;
  isList?: boolean;
  onShare: (e: React.MouseEvent) => void;
  onBookmarkToggle?: (e: React.MouseEvent) => void;
  isBookmarked?: boolean;
}

function ViewCard({ 
  view, 
  onClick, 
  isCompact = false,
  isList = false,
  onShare,
  onBookmarkToggle,
  isBookmarked = false
}: ViewCardProps) {
  const { toast } = useToast();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showTooltip, setShowTooltip] = useState<string | null>(null);
  const cardRef = useRef<HTMLDivElement>(null);

  const statusColors = {
    confirmed: 'bg-green-500 border-green-400 text-green-400',
    likely: 'bg-blue-500 border-blue-400 text-blue-400',
    ifclear: 'bg-cyan-500 border-cyan-400 text-cyan-400',
    plausible: 'bg-yellow-500 border-yellow-400 text-yellow-400',
    dubious: 'bg-orange-500 border-orange-400 text-orange-400',
    unlikely: 'bg-red-500 border-red-400 text-red-400'
  };

  const statusLabels = {
    confirmed: 'Confirmed',
    likely: 'Likely',
    ifclear: 'If Clear',
    plausible: 'Plausible',
    dubious: 'Dubious',
    unlikely: 'Unlikely'
  };

  // Map status to color classes for border-l
  const statusBorderColors = {
    confirmed: 'border-l-green-400',
    likely: 'border-l-blue-400',
    ifclear: 'border-l-cyan-400',
    plausible: 'border-l-yellow-400',
    dubious: 'border-l-orange-400',
    unlikely: 'border-l-red-400'
  };

  // Helper functions for status colors
  const getStatusDotColor = (status: string) => {
    return statusColors[status as keyof typeof statusColors] || 'bg-gray-500 border-gray-400 text-gray-400';
  };

  const getStatusTextColor = (status: string) => {
    const colorClass = statusColors[status as keyof typeof statusColors];
    if (!colorClass) return 'text-gray-400';
    
    const parts = colorClass.split(' ');
    return parts[2] || 'text-gray-400';
  };

  const getStatusBorderColor = (status: string) => {
    return statusBorderColors[status as keyof typeof statusBorderColors] || 'border-l-gray-400';
  };

  const getStatusLabel = (status: string) => {
    return statusLabels[status as keyof typeof statusLabels] || status || 'Unknown';
  };

  // Format distance for display
  const distanceParts = view.distance.match(/^([\d.]+)\s*(km|mi)/i);
  const distance = distanceParts ? {
    value: Number.parseFloat(distanceParts[1]),
    unit: distanceParts[2].toLowerCase()
  } : { value: 0, unit: 'km' };

  // Prevent card click when clicking buttons
  const handleButtonClick = (e: React.MouseEvent, callback: (e: React.MouseEvent) => void) => {
    e.stopPropagation();
    callback(e);
  };

  // Handle image load
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div
      ref={cardRef}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick();
        }
      }}
      className={cn(
        'relative rounded-lg overflow-hidden transition-all duration-300 cursor-pointer group',
        'bg-card/90 backdrop-blur-sm border border-border/30 shadow-md',
        'hover:shadow-lg hover:shadow-primary/10 hover:border-primary/40',
        isList ? 'flex' : 'block'
      )}
      aria-label={`View from ${view.source} to ${view.destination}, distance: ${view.distance}`}
    >
      {/* Sci-fi top corner accents */}
      <div className="absolute top-0 left-0 w-[40px] h-[1px] bg-gradient-to-r from-primary/70 to-transparent" />
      <div className="absolute top-0 left-0 w-[1px] h-[40px] bg-gradient-to-b from-primary/70 to-transparent" />
      <div className="absolute top-0 right-0 w-[40px] h-[1px] bg-gradient-to-l from-primary/70 to-transparent" />
      <div className="absolute top-0 right-0 w-[1px] h-[40px] bg-gradient-to-b from-primary/70 to-transparent" />

      {/* Image section */}
      <div className={cn(
        "relative",
        isList ? "w-40 min-w-[160px] h-full" : "h-48",
        !imageLoaded && "bg-muted/30 animate-pulse"
      )}>
        <img
          src={view.image}
          alt={`View from ${view.source}`}
          onLoad={handleImageLoad}
          className={cn(
            "w-full h-full object-cover transition-all duration-500",
            !imageLoaded && "opacity-0",
            "group-hover:scale-[1.03]"
          )}
        />
        
        {/* Image loading state */}
        {!imageLoaded && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Camera className="h-8 w-8 text-muted-foreground/50" />
          </div>
        )}
        
        {/* Image overlay gradient with sci-fi scan line effect */}
        <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/40 to-transparent 
                        before:absolute before:inset-0 before:bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZGVmcz4KICA8cGF0dGVybiBpZD0ic2NhbmxpbmVzIiBwYXR0ZXJuVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI0Ij4KICAgIDxsaW5lIHgxPSIwIiB5MT0iMC41IiB4Mj0iMTAwJSIgeTI9IjAuNSIgc3Ryb2tlPSIjZmZmZmZmMDMiIHN0cm9rZS13aWR0aD0iMSIvPgogIDwvcGF0dGVybj4KPC9kZWZzPgo8cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI3NjYW5saW5lcykiIC8+Cjwvc3ZnPg==')] before:opacity-30" />
        
        {/* Fullscreen icon on hover with sci-fi effect */}
        <div className={cn(
          "absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-300 z-10",
          "group-hover:opacity-100"
        )}>
          <div className="rounded-full bg-black/50 backdrop-blur p-3 border border-primary/30 shadow-md shadow-primary/20 
                         before:absolute before:inset-0 before:rounded-full before:border before:border-white/5 before:scale-[1.2] before:opacity-0 before:animate-ping before:animation-delay-[400ms]">
            <Maximize2 className="h-5 w-5 text-primary/90" />
          </div>
        </div>
                
        {/* Distance chip moved to left side */}
        <div className="absolute top-3 left-3 z-10">
          <div className="flex items-center gap-1.5 bg-black/50 backdrop-blur-sm px-2.5 py-1 rounded-full shadow-sm text-white border border-white/10 group-hover:border-primary/30 transition-all duration-300">
            <Navigation className="h-3.5 w-3.5 text-primary" />
            <div className="flex items-baseline">
              <span className="text-sm font-semibold">{distance.value.toLocaleString()}</span>
              <span className="text-xs ml-1 opacity-80">{distance.unit}</span>
            </div>
          </div>
        </div>

        {/* Action buttons moved to right side */}
        <div className="absolute top-3 right-3 flex flex-row items-end gap-2 z-10">
          {/* Share button */}
          <div className="relative">
            <button
              type="button"
              onClick={(e) => handleButtonClick(e, onShare)}
              onMouseEnter={() => setShowTooltip('share')}
              onMouseLeave={() => setShowTooltip(null)}
              className="p-1.5 rounded-full shadow-sm bg-black/60 backdrop-blur-sm border border-white/10 text-white 
                        hover:bg-black/70 hover:border-primary/30 hover:text-primary transition-all duration-300"
              aria-label="Share view"
            >
              <Share2 className="h-4 w-4" />
            </button>
            {showTooltip === 'share' && (
              <div className="absolute top-full mt-1 right-0 px-2 py-1 text-xs font-medium text-white bg-black/80 rounded-md backdrop-blur-sm whitespace-nowrap border-l border-primary/50 no-animation">
                Share View
              </div>
            )}
          </div>
          
          {/* View type indicator */}
          <div className="relative">
            <div 
              className="p-1.5 rounded-full shadow-sm bg-black/60 backdrop-blur-sm border border-white/10 text-white
                        hover:border-primary/30 hover:text-primary transition-all duration-300"
              onMouseEnter={() => setShowTooltip('type')}
              onMouseLeave={() => setShowTooltip(null)}
            >
              <Mountain className="h-4 w-4" />
            </div>
            {showTooltip === 'type' && (
              <div className="absolute top-full mt-1 right-0 px-2 py-1 text-xs font-medium text-white bg-black/80 rounded-md backdrop-blur-sm whitespace-nowrap border-l border-primary/50 no-animation">
                Mountain View
              </div>
            )}
          </div>
        </div>
        
        {/* Status indicator with enhanced color visibility */}
        <div className="absolute bottom-3 right-3 z-10">
          <div className={cn(
            "inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium capitalize",
            "bg-black/70 backdrop-blur-sm border-l-2 shadow-sm",
            getStatusBorderColor(view.status)
          )}>
            <span className={cn(
              "w-2 h-2 rounded-full",
              getStatusDotColor(view.status)
            )} />
            <span className={cn(
              getStatusTextColor(view.status)
            )}>
              {getStatusLabel(view.status)}
            </span>
          </div>
        </div>
      </div>

      {/* Content section with directional flow design */}
      <div className={cn("relative p-3", isList && "flex-1")}>
        <div className={cn(isList ? "flex items-stretch gap-3" : "block")}>
          {/* Source to Destination Flow Container */}
          <div className={cn(
            "relative rounded-lg overflow-hidden flex-1",
            isList ? "" : "mb-3"
          )}>
            {/* Connection Line - Animated gradient flow */}
            <div className={cn(
              "absolute left-0 right-0 top-1/2 transform -translate-y-1/2 z-0 mx-6",
              isList ? "h-[2px]" : "h-[3px]"
            )}>
              <div className="h-full w-full bg-gradient-to-r from-cyan-500/30 via-primary/60 to-indigo-500/30 relative overflow-hidden">
                {/* Animated light pulse effect - enhanced with multiple pulses */}
                <div className="absolute inset-0">
                  <div className="h-full w-8 bg-gradient-to-r from-transparent via-white to-transparent absolute -left-8 animate-pulse animate-slide" />
                  <div className="h-full w-6 bg-gradient-to-r from-transparent via-white/70 to-transparent absolute -left-12 animate-pulse animate-slide animation-delay-300" />
                </div>
              </div>
            </div>
            
            {/* Connection Arrow - Enhanced with subtle animation */}
            <div className="absolute right-5 top-1/2 transform -translate-y-1/2 z-0 text-primary">
              <div className="h-4 w-4 rounded-full bg-primary/10 flex items-center justify-center border border-primary/40 shadow-sm shadow-primary/10 group-hover:scale-110 group-hover:bg-primary/20 transition-all duration-300">
                <ChevronRight className="h-3 w-3 group-hover:translate-x-0.5 transition-transform duration-300" />
              </div>
            </div>

            {/* Source and Destination Cards */}
            <div className={cn(
              "relative z-10 flex",
              isList ? "gap-3 items-stretch" : "flex-col gap-4"
            )}>
              {/* Source location - modern glass card with enhanced hover effects */}
              <div className={cn(
                "rounded-lg bg-card/80 backdrop-blur-sm border border-border/50 hover:border-primary/30 p-3 relative overflow-hidden shadow-md transition-all duration-300 group/source flex-1",
                "group-hover:shadow-lg group-hover:shadow-primary/5",
                !isList && "pb-5", // Extra padding at bottom for non-list view
                isList && "max-w-[45%]" // Control width in list mode
              )}>
                {/* Subtle corner accent */}
                <div className="absolute top-0 left-0 w-[30px] h-[1px] bg-gradient-to-r from-primary/70 to-transparent" />
                <div className="absolute top-0 left-0 w-[1px] h-[30px] bg-gradient-to-b from-primary/70 to-transparent" />
                
                <div className="flex items-center gap-3 mb-2 pb-2 relative">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full bg-primary/10 text-primary group-hover/source:bg-primary/20 transition-all duration-300 border border-primary/20 shadow-sm shadow-primary/5 group-hover/source:shadow-primary/20 group-hover/source:scale-105">
                    <MapPin className="h-4 w-4" />
                  </div>
                  <div>
                    <span className="text-xs text-primary/70 uppercase tracking-wider mb-1 block group-hover/source:text-primary/90 transition-colors duration-300">From</span>
                    <h3 className="font-medium text-sm text-card-foreground leading-tight line-clamp-1">{view.source}</h3>
                  </div>
                </div>
                
                <div className="flex flex-wrap items-center text-xs text-muted-foreground gap-1.5 ml-11">
                  <span className="inline-flex items-center px-2 py-0.5 rounded-full bg-background/60 border border-border/30 truncate max-w-full hover:border-primary/30 transition-colors duration-300">
                    {view.state}
                  </span>
                  <span className="inline-flex items-center px-2 py-0.5 rounded-full bg-background/60 border border-border/30 truncate max-w-full hover:border-primary/30 transition-colors duration-300">
                    {view.country}
                  </span>
                </div>
              </div>
              
              {/* Destination location - modern glass card with enhanced hover effects */}
              <div className={cn(
                "rounded-lg bg-card/80 backdrop-blur-sm border border-border/50 hover:border-primary/30 p-3 relative overflow-hidden shadow-md transition-all duration-300 group/dest flex-1",
                "group-hover:shadow-lg group-hover:shadow-primary/5",
                !isList && "pt-5", // Extra padding at top for non-list view
                isList && "max-w-[45%]" // Control width in list mode
              )}>
                {/* Subtle corner accent */}
                <div className="absolute top-0 right-0 w-[30px] h-[1px] bg-gradient-to-l from-primary/70 to-transparent" />
                <div className="absolute top-0 right-0 w-[1px] h-[30px] bg-gradient-to-b from-primary/70 to-transparent" />
                
                <div className="flex items-center gap-3 mb-2 pb-2 relative">
                  <div className="flex h-8 w-8 items-center justify-center rounded-full bg-primary/10 text-primary group-hover/dest:bg-primary/20 transition-all duration-300 border border-primary/20 shadow-sm shadow-primary/5 group-hover/dest:shadow-primary/20 group-hover/dest:scale-105">
                    <Navigation className="h-4 w-4" />
                  </div>
                  <div>
                    <span className="text-xs text-primary/70 uppercase tracking-wider mb-1 block group-hover/dest:text-primary/90 transition-colors duration-300">To</span>
                    <h3 className="font-medium text-sm text-card-foreground leading-tight line-clamp-1">{view.destination}</h3>
                  </div>
                </div>
                
                <div className="flex flex-wrap items-center text-xs text-muted-foreground gap-1.5 ml-11">
                  <span className="inline-flex items-center px-2 py-0.5 rounded-full bg-background/60 border border-border/30 truncate max-w-full hover:border-primary/30 transition-colors duration-300">
                    {view.destinationState}
                  </span>
                  <span className="inline-flex items-center px-2 py-0.5 rounded-full bg-background/60 border border-border/30 truncate max-w-full hover:border-primary/30 transition-colors duration-300">
                    {view.destinationCountry || view.country}
                  </span>
                </div>
              </div>
            </div>
          </div>
          
          {/* Additional info for list view - Enhanced layout */}
          {isList && (
            <div className="rounded-lg bg-card/80 backdrop-blur-sm border border-border/50 hover:border-primary/20 p-3 relative overflow-hidden shadow-md transition-all duration-300 flex flex-col justify-between min-w-[200px] w-[200px]">
              {/* Subtle corner accents */}
              <div className="absolute top-0 right-0 w-[30px] h-[1px] bg-gradient-to-l from-primary/70 to-transparent" />
              <div className="absolute top-0 right-0 w-[1px] h-[30px] bg-gradient-to-b from-primary/70 to-transparent" />
              <div className="absolute bottom-0 left-0 w-[30px] h-[1px] bg-gradient-to-r from-primary/70 to-transparent" />
              <div className="absolute bottom-0 left-0 w-[1px] h-[30px] bg-gradient-to-t from-primary/70 to-transparent" />
            
              {/* Status information with enhanced visuals */}
              <div>
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center gap-2">
                    <div className={cn(
                      "w-2.5 h-2.5 rounded-full animate-glow",
                      getStatusDotColor(view.status)
                    )} />
                    <span className={cn(
                      "text-xs font-medium capitalize",
                      getStatusTextColor(view.status)
                    )}>
                      {getStatusLabel(view.status)}
                    </span>
                  </div>
                  
                  <div className="flex items-center gap-2">
                    {isBookmarked && (
                      <div className="text-yellow-400 animate-pulse">
                        <Bookmark className="h-4 w-4 fill-current" />
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Distance display with enhanced visuals */}
                <div className="mb-4 mt-3 p-2 rounded-lg bg-black/30 border border-primary/20 backdrop-blur-sm">
                  <div className="flex items-center gap-2">
                    <div className="h-7 w-7 flex items-center justify-center rounded-full bg-primary/10 text-primary border border-primary/20">
                      <Navigation className="h-3.5 w-3.5" />
                    </div>
                    <div className="flex flex-col">
                      <span className="text-xs text-primary/70">Distance</span>
                      <div className="flex items-baseline">
                        <span className="text-base font-semibold text-white">{distance.value.toLocaleString()}</span>
                        <span className="text-xs ml-1 text-white/70">{distance.unit}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Action buttons with enhanced styles */}
              <div className="mt-auto">
                <div className="flex justify-end items-center gap-2 mt-2">
                  {onBookmarkToggle && (
                    <button
                      type="button"
                      onClick={(e) => handleButtonClick(e, onBookmarkToggle)}
                      className="p-2 rounded-full bg-black/60 backdrop-blur-sm border border-white/10 text-white 
                        hover:bg-black/70 hover:border-primary/30 hover:text-primary transition-all duration-300
                        hover:shadow-md hover:shadow-primary/10"
                      aria-label={isBookmarked ? "Remove from bookmarks" : "Add to bookmarks"}
                    >
                      {isBookmarked ? (
                        <Bookmark className="h-4 w-4 fill-current" />
                      ) : (
                        <BookmarkPlus className="h-4 w-4" />
                      )}
                    </button>
                  )}
                  
                  <button
                    type="button"
                    onClick={(e) => handleButtonClick(e, onShare)}
                    className="p-2 rounded-full bg-black/60 backdrop-blur-sm border border-white/10 text-white 
                        hover:bg-black/70 hover:border-primary/30 hover:text-primary transition-all duration-300
                        hover:shadow-md hover:shadow-primary/10"
                    aria-label="Share view"
                  >
                    <Share2 className="h-4 w-4" />
                  </button>
                  
                  <button
                    type="button"
                    onClick={onClick}
                    className="p-2 rounded-full bg-primary/80 backdrop-blur-sm border border-primary/30 text-white 
                        hover:bg-primary hover:border-primary/50 transition-all duration-300 ml-1
                        hover:shadow-md hover:shadow-primary/20"
                    aria-label="View details"
                  >
                    <ChevronRight className="h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// Export both as default and named export
export default ViewCard;
export { ViewCard };