import React from 'react';
import { SEO } from '../../lib/seo';

// FAQ items that will be used both for the page and structured data
const faqItems = [
  {
    question: "What is TheViewShed?",
    answer: "TheViewShed is a community-driven directory of line-of-sight views from around the world. We catalog views where you can see one location from another across distances."
  },
  {
    question: "How are view distances calculated?",
    answer: "View distances are calculated using geographic coordinates and great-circle distance formulas, measuring the direct line-of-sight distance between the source and destination points."
  },
  {
    question: "What do the different status categories mean?",
    answer: "We use several status categories: 'Confirmed' (verified views), 'Likely' (high probability), 'If Clear' (possible in ideal weather), 'Plausible' (theoretically possible), 'Dubious' (questionable), and 'Unlikely' (improbable but submitted)."
  },
  {
    question: "How can I contribute to TheViewShed?",
    answer: "You can contribute by submitting views you've discovered, verifying existing views, providing better photographs, or helping with data accuracy through our submission form."
  },
  {
    question: "Can I use the data from TheViewShed for my project?",
    answer: "The directory data is available for non-commercial use with attribution. For commercial use, please contact us to discuss licensing options."
  }
];

export function FAQPageSEO() {
  // Create FAQPage structured data for rich results
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqItems.map(item => ({
      "@type": "Question",
      "name": item.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": item.answer
      }
    }))
  };

  return (
    <SEO
      title="Frequently Asked Questions | TheViewShed"
      description="Find answers to common questions about TheViewShed, our line-of-sight view directory, how distances are calculated, status categories, and how to contribute."
      keywords="viewshed FAQ, line of sight questions, view distance calculation, contribute views, viewshed status categories"
      structuredData={structuredData}
    />
  );
} 